import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import HelpIcon from '@mui/icons-material/Help';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  MenuItem,
  FormControlLabel
} from '@mui/material'
import { styled } from '@mui/material/styles';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  authActions,
  orgaoAction,
  cidadeAction,
  estadoAction,
  usuarioAction,
  usuarioOrgaoAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { history, cnpjMask, stringUtils, phoneMask, MANUAIS_CATEGORIAS } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import { UsuarioEditModal } from './usuarioEditModal.component';
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const SwitchBlue = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#1C5585',
    }
  }
}));

const OrgaoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const confirm = useConfirm()

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)

  const [modalUsuario, setModalUsuario] = useState(false);
  const toggleUsuario = () => setModalUsuario(!modalUsuario);

  const { cidade } = useSelector(
    (state) => state.cidade
  )

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const estados = useSelector((state) => state.estado.estado)

  const options = cidade.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {

    dispatch(orgaoAction.clear())
    dispatch(estadoAction.getEstadoSelect())

    if (params.id) {
      dispatch(orgaoAction.getOrgaoById(params.id))
    } else {
      dispatch(orgaoAction.clear())
    }

  }, []);

  useEffect(() => {
    if (props.orgao.idCidade != 0 && params.id != undefined) {
      setValue(props.orgao.cidade)
    }
  }, [props.orgao.idCidade])

  const handleAprovaCadastroOrgao = () => {
    confirm({
      title: 'Você deseja liberar o acesso ao sistema para este Órgão?',
      description: 'Essa operação é irreversível',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Aprovando...' : 'Sim, aprovar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(orgaoAction.editAprovaCadastroOrgao(params.id))
    })
  }

  const handleRecusaCadastroOrgao = () => {
    confirm({
      title: 'Você deseja recusar o acesso ao sistema para este Órgão?',
      description: 'Essa operação é irreversível',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Recusando...' : 'Sim, recusar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(orgaoAction.editRecusaCadastroOrgao(params.id))
    })
  }

  const handleRemoveOnList = id => {
    confirm({
      title: 'Você deseja excluir este usuário?',
      description: 'Essa operação desvinculará o usuário deste órgao',
      disabled: props.orgao.isLoading,
      confirmationText: props.orgao.isLoading ? 'Excluindo usuário...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      props.orgao.usuariosOrgaos = props.orgao.usuariosOrgaos.filter(ts => ts.idUsuario != parseInt(id));
      forceUpdate(n => !n);
    })
  };

  const handleOpenModal = () => {
    toggleUsuario();
    dispatch(usuarioAction.clear())
  }

  useEffect(() => {
    if (props.orgao.idEstado) {
      dispatch(cidadeAction.getCidadeByIdEstado(props.orgao.idEstado))
    }
  }, [props.orgao.idEstado])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {

    if (obj != null) {
      let selectedCidade = cidade.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        cidadeAction.changeDetailsCidade(
          selectedCidade[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  useEffect(() => {

  }, [ready, props.orgao.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(orgaoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.orgao.usuariosOrgaos])

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.orgao.nome,
      idCidade: value ? value.id : 0,
      idEstado: props.orgao.idEstado,
      celular: props.orgao.celular && props.orgao.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      cnpj: props.orgao.cnpj && props.orgao.cnpj.split('.').join('').replace(/[-/.]/g, ''),
      email: props.orgao.email ? props.orgao.email.toLowerCase() : '',
      endereco: props.orgao.endereco,
      telefone: props.orgao.telefone && props.orgao.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      usuariosOrgaos: props.orgao.usuariosOrgaos,
      naoExigeOrcamentos: props.orgao.naoExigeOrcamentos,
      mapaPublico: props.orgao.mapaPublico
    }


    if (params.id) {
      dispatch(orgaoAction.editOrgaoInfo(params.id, payload, true))
    } else {
      dispatch(orgaoAction.createOrgao(payload))
    }
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Órgão'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Órgão'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Órgãos - Editar Órgão'
          : 'Gerência de Órgãos - Novo Órgão'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formOrgao"
                onSubmit={(event) => handleSubmit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Órgãos"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.orgao.nome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idEstado"
                        label="Estado *"
                        value={props.orgao.idEstado}
                        onChange={handleChange('idEstado')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idEstado"
                      >
                        <MenuItem disabled value="">
                          <em>Estado *</em>
                        </MenuItem>
                        {!isEmpty(estados) &&
                          undefined !== estados &&
                          estados.length &&
                          estados.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={value}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione uma cidade..." fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnpj"
                        label="CNPJ"
                        margin="normal"
                        onChange={handleChange('cnpj')}
                        value={cnpjMask(props.orgao.cnpj) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="endereco"
                        label="Endereço"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('endereco')}
                        value={props.orgao.endereco || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <FormControlLabel
                        control={
                          <SwitchBlue
                            checked={props.orgao.mapaPublico}
                            onChange={handleChange('mapaPublico')}
                          />
                        }
                        label="Permite a visualização pública da obra?"
                      />
                      <FormControlLabel
                        control={
                          <SwitchBlue
                            checked={props.orgao.naoExigeOrcamentos}
                            onChange={handleChange('naoExigeOrcamentos')}
                          />
                        }
                        label="Não exige que as obras recebam propostas"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid container lg={12} md={12} xl={12} xs={12}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Dados de contato
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          validators={['required', 'isEmail']}
                          fullWidth
                          label="E-mail *"
                          onInput={stringUtils.toInputLowerCase}
                          errorMessages={[
                            'Campo obrigatório!', 'E-mail inválido'
                          ]}
                          value={props.orgao.email || ''}
                          className={classes.textField}
                          onChange={handleChange('email')}
                          id="email"
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="telefone"
                          label="Telefone"
                          margin="normal"
                          onChange={handleChange('telefone')}
                          value={phoneMask(props.orgao.telefone) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="celular"
                          label="Celular"
                          margin="normal"
                          onChange={handleChange('celular')}
                          value={phoneMask(props.orgao.celular) || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {params.id &&
                    <>
                      <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                        <Divider />
                      </Grid>
                      <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                        <Grid container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 7 }} spacing={2} >
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                            <Typography component="h4" variant="subtitle" align="right" >
                              Cadastrar e vincular usuário ao órgão
                            </Typography>
                          </Grid>
                          <AccessControl
                            rule={'usuariosOrgaos.create'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <Grid item lg={4} md={4} xl={4} xs={12} align="left">
                                <CustomButton
                                  dark={true}
                                  name="btnUsuarios"
                                  id="btnUsuarios"
                                  className="btn"
                                  variant="contained"
                                  component='a'
                                  onClick={() => handleOpenModal()}>Cadastrar usuário&nbsp;<FontAwesomeIcon icon={faUserPlus} />
                                </CustomButton>
                              </Grid>
                            )}
                            no={() => <AccessDenied />}
                          />
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: 6, display: 'flex', flexDirection: 'row', marginLeft: 15, marginRight: 15, justifyContent: 'center' }} align="center">
                          {
                            (props.orgao.usuariosOrgaos != null && props.orgao.usuariosOrgaos.length > 0 ?
                              props.orgao.usuariosOrgaos.map(n => {
                                return (
                                  (n.id !== '' && n.excluido != true ?
                                    <AccessControl
                                      rule={'usuariosOrgaos.delete'} //permissão necessária para acessar conteúdo
                                      yes={() => (
                                        <Grid item lg={3} md={3} xl={3} xs={12} style={{ marginBottom: 2 }}>
                                          <Card>
                                            <CardContent>
                                              <Typography variant="h6" component="div">
                                                {(n.usuario ? n.usuario.nome.toUpperCase() : n.idUsuario)}
                                              </Typography>
                                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {(n.usuario ? n.usuario.email : '')}
                                              </Typography>
                                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                {(n.usuario ? n.usuario.idTipoUsuario == 1 ? 'ADMINISTRADOR' : n.usuario.idTipoUsuario == 2 ? 'GESTOR ÓRGÃO PÚBLICO' : n.usuario.idTipoUsuario == 3 ? 'PRESTADOR DE SERVIÇO' : n.usuario.idTipoUsuario == 4 ? 'MOTORISTA' : n.usuario.idTipoUsuario == 5 ? 'FISCAL' : n.usuario.idTipoUsuario == 6 ? 'USUÁRIO ÓRGÃO PÚBLICO' : '' : '')}
                                              </Typography>
                                              <Tooltip
                                                title="Excluir usuário"                                          >
                                                <Button sx={{ fontWeigth: 400, marginLeft: 7, marginRight: 7 }} onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                                  <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000', fontSize: 17 }} />
                                                </Button>
                                              </Tooltip>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      )}
                                      no={() => (
                                        <Grid item lg={3} md={3} xl={3} xs={12} style={{ marginBottom: 2 }}>
                                          <Card>
                                            <CardContent>
                                              <Typography variant="h6" component="div">
                                                {(n.usuario ? n.usuario.nome.toUpperCase() : n.idUsuario)}
                                              </Typography>
                                              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {(n.usuario ? n.usuario.email : '')}
                                              </Typography>
                                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                {(n.usuario ? n.usuario.idTipoUsuario == 1 ? 'ADMINISTRADOR' : n.usuario.idTipoUsuario == 2 ? 'GESTOR ÓRGÃO PÚBLICO' : n.usuario.idTipoUsuario == 3 ? 'PRESTADOR DE SERVIÇO' : n.usuario.idTipoUsuario == 4 ? 'MOTORISTA' : n.usuario.idTipoUsuario == 5 ? 'FISCAL' : '' : '')}
                                              </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      )}
                                    />
                                    : null)
                                );
                              })
                              : null)
                          }
                        </Grid>
                      </Grid>
                    </>
                  }
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlign: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/Orgaos')}
                    >
                      Cancelar
                    </CustomButton>
                    {orgao.id && authActions.isGestor() ?
                      <>
                        <CustomButton
                          variant="outlined"
                          onClick={handleRecusaCadastroOrgao}
                          disabled={props.orgao.cadastroLiberado == false || props.orgao.isLoading}
                          startIcon={<CloseIcon />}
                          endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                        >
                          {props.orgao.cadastroLiberado == false ? 'Acesso recusado' : props.orgao.isLoading ? 'Recusando acesso...' : 'Recusar acesso'}
                        </CustomButton>
                        <CustomButton
                          variant="outlined"
                          onClick={handleAprovaCadastroOrgao}
                          disabled={props.orgao.cadastroLiberado == true || props.orgao.isLoading}
                          startIcon={<DoneIcon />}
                          endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                        >
                          {props.orgao.cadastroLiberado == true ? 'Acesso liberado' : props.orgao.isLoading ? 'Liberando acesso...' : 'Liberar acesso'}
                        </CustomButton>
                      </>
                      : null}
                    <CustomButton
                      variant="contained"
                      dark={true}
                      type="submit"
                      disabled={props.orgao.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.orgao.isLoading ? 'Atualizando órgão...' : 'Atualizar') : (props.orgao.isLoading ? 'Salvando órgão...' : 'Salvar')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid >
      </Grid >

      {
        (modalUsuario ?
          <UsuarioEditModal toggle={toggleUsuario} modal={modalUsuario} props={props} idorgao={params.id} />
          :
          null
        )
      }
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_ORGAO_ADMIN}
          />
          :
          null
        )
      }
    </Page >
  )
}

OrgaoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedOrgaoEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrgaoEdit))
)
export { connectedOrgaoEditPage as OrgaoEdit }
