import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, obraAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import TitleContentInformation from '../TitleContentInformation'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  Autocomplete,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import {
  ValidatorForm,
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS } from '../../utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioOcorrenciaObra = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [valueObra, setValueObra] = useState(null);
  const [inputValueObra, setInputValueObra] = useState('');
  const [openObra, setOpenObra] = useState(false)

  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { obra } = useSelector(
    (state) => state.obra)

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  useEffect(() => {
    dispatch(obraAction.getObraSelect({ IdOrgao: idOrgao }));

  }, [idOrgao]);

  useEffect(() => {
    dispatch(relatorioAction.clear());
  }, [])

  const optionsObras = obra.map(u => ({ id: u.value, nome: u.text }));

  const handlePrint = () => {
    if (valueObra && valueObra != undefined) {
      window.open('/relatorioOcorrenciasObraPrint', '_blank');
    }
  }

  const handleExportExcel = () => {

    let payload = {
      IdObra: valueObra ? valueObra.id : 0
    };

    dispatch(relatorioAction.getRelatorioOcorrenciaObraArquivo(payload))
  }

  const handleSubmit = (event) => {
    if (valueObra && valueObra != undefined) {

      let payload = {
        IdObra: valueObra ? valueObra.id : 0,
      };

      dispatch(relatorioAction.getRelatorioOcorrenciaObra(payload, true));
    } else {
      toast.error('Selecione uma obra!')
    }
  };

  useEffect(() => {
    if (valueObra) {
      handleChangeAutoCompleteObra(valueObra)
    }
  }, [valueObra])

  const handleChangeAutoCompleteObra = (obj) => {
    if (obj != null) {
      let selectedObra = obra.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        obraAction.changeDetailsObra(
          selectedObra[0]
        )
      )
    } else {

    }
    setOpenObra(false)
  }

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Ocorrências de uma Obra">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioOcorrenciasObra"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Ocorrências de uma Obra'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={7} md={7} xl={7} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueObra}
                          onChange={(event, newValue) => {
                            setValueObra(newValue);
                          }}
                          inputValue={inputValueObra}
                          onInputChange={(event, newInputValueObra) => {
                            setInputValueObra(newInputValueObra);
                          }}
                          id="controllable-states-demo"
                          options={optionsObras}
                          margin="normal"
                          variant="outlined"
                          defaultValue={valueObra}
                          getOptionLabel={(o) => (o ? o.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Obra *... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>DATA</strong></TableCell>
                        <TableCell><strong>OCORRÊNCIA</strong></TableCell>
                        <TableCell><strong>USUÁRIO</strong></TableCell>
                        <TableCell><strong>ÓRGÃO</strong></TableCell>
                        <TableCell><strong>EMPRESA</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.dataHora ? moment(n.dataHora).format('DD/MM/yyyy HH:mm') : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.descricaoOcorrencia ? n.descricaoOcorrencia.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.usuario ? n.usuario.toUpperCase() : '-'} (<span style={{ fontSize: 11 }}>{n.usuarioEmail}</span>)
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.orgao ? n.orgao.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.empresa ? n.empresa.toUpperCase() : '-'}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (valueObra != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={6} colSpan={5} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={5} align="center">Informe uma obra</TableCell>
                          </TableRow>
                        )
                      }
                      {undefined !== relatorio && relatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={2} colSpan={4}><b>TOTAL DE OCORRÊNCIAS</b></TableCell>
                          <TableCell rowSpan={2}><b>{getTotal()}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.RELATORIO_OCORRENCIA_OBRA}
          />
          :
          null
        )
      }
    </Page>
  )
}
RelatorioOcorrenciaObra.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioOcorrenciaObraPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioOcorrenciaObra))
)
export { connectedRelatorioOcorrenciaObraPage as RelatorioOcorrenciaObra }
