import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { stringUtils } from '../../utils'
import { withStyles } from '@mui/styles'
import { toast } from 'react-toastify'
import { crudService } from '_services'
import Stars from '../../components/Stars/Stars';
import {
  Avatar,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
  Tooltip,
  Skeleton,
  Input
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  obraAvaliacaoAction,
} from '../../_actions'
import { withRouter, } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import StarRating from "../../components/Stars/StarRating";
import EditIcon from '@mui/icons-material/Edit'
import { useDebounce } from 'use-debounce';
import { AccessControl } from '../../components/Utils/AccessControl'
import CustomButton from '../../components/CustomButton'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

function isEditable(e) {
  if (e == '2' || e == '3' || e == '4' || e == '5' || e == '6') {
    return false
  }
  else return true
}

const ObraAvaliacaoEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const { classes, idObra, ...rest } = props
  const { match: { params } } = props;
  const { totalRegistros } = props.obraAvaliacao
  const { isLoading } = props.obra
  const formRef = React.createRef(null);
  const [key, setKey] = useState(1);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');

  const [open, setOpen] = useState(false)
  const [ratingClear, setRatingClear] = useState(false);
  const [imagem, setImagem] = useState('')
  const [file, setFile] = useState(undefined)

  const [empresas, setEmpresas] = useState([]);
  const { obraAvaliacao } = useSelector(
    (state) => state.obraAvaliacao)

  const { tipoAnexo } = useSelector(
    (state) => state.tipoAnexo)

  useEffect(() => {
    dispatch(obraAvaliacaoAction.clear())

    if (props.authentication.user.idTipoUsuario == 3) {
      setEmpresas([{ ...props.authentication.user.usuariosEmpresas[0], selected: true }]);
    } else {
      crudService
        .get(`obrasOrcamentosItens/itensAprovados/${idObra}`)
        .then(response => setEmpresas(response.data?.map(r => { return { ...r, selected: false } })));
    }
  }, [])

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdObra: (idObra && idObra != '' && idObra)
      };

      dispatch(obraAvaliacaoAction.getObraAvaliacao(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idObra]);

  useEffect(() => {

  }, [totalRegistros, isLoading]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (id) => {
    dispatch(obraAvaliacaoAction.getObraAvaliacaoById(id))
  }

  const handleClean = () => {
    dispatch(obraAvaliacaoAction.clear())

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdObra: (idObra && idObra != '' && idObra)
    };

    dispatch(obraAvaliacaoAction.getObraAvaliacao(filterModel, true));

    let changeNotaEvent = {
      type: 'text',
      target: {
        value: 0
      }
    };

    dispatch(obraAvaliacaoAction.onChangeProps('nota', changeNotaEvent));
    setRatingClear(true);

    props.obraAvaliacao.nota = 0

    console.log('propssssssssss Nota', props.obraAvaliacao.nota)
    setKey(0);
  }

  const handleDelete = (id) => {
    confirm({
      title: 'Você deseja excluir este anexo?',
      description: 'Essa operação é irreversível',
      disabled: props.obraAvaliacao.isLoading,
      confirmationText: props.obraAvaliacao.isLoading ? 'Excluindo anexo...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraAvaliacaoAction.deleteObraAvaliacaoById(id))
    })
  }

  const handleChange = (prop) => (event) => {
    dispatch(obraAvaliacaoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    if (props.obraAvaliacao.descricao == '' || props.obraAvaliacao.descricao == null) {
      toast.error('Informe uma Descrição para a avaliação!')
    }
    else if (props.obraAvaliacao.idEmpresa == '' || props.obraAvaliacao.idEmpresa == null) {
      toast.error('Informe uma Empresa para a avaliação!')
    } else if (props.obraAvaliacao.nota == '' || props.obraAvaliacao.nota == null || props.obraAvaliacao.nota == 0) {
      toast.error('Informe uma Nota para a avaliação!')
    } else {
      let payload = {
        id: props.obraAvaliacao.id,
        idObra: parseInt(idObra),
        idEmpresa: props.obraAvaliacao.idEmpresa,
        idOrgao: props.obra.idOrgao,
        nota: props.obraAvaliacao.nota,
        descricao: props.obraAvaliacao.descricao,
      }

      if (props.obraAvaliacao.id) {
        dispatch(obraAvaliacaoAction.editObraAvaliacaoInfo(props.obraAvaliacao.id, payload, true))
        setRatingClear(true);
      } else {
        dispatch(obraAvaliacaoAction.createObraAvaliacao(payload))
        setRatingClear(true);
      }
    }

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Avaliação'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Avaliação'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (props.obraAvaliacao.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (

    //{isEditable(props.obra.ultimoEvento) == true ?
    //permitir enviar anexos de NOTAS mesmo depois de já aprovado o orçamento

    <Grid item lg={12} md={12} xl={12} xs={12}>
      <Grid item md={12} xs={12}>
        <ValidatorForm
          className={classes.form}
          ref={formRef}
          id="formAvaliacao"
          onSubmit={(event) => handleSubmit(event)}
        >
          <CardHeader
            subheader="Cadastro de Avaliação"
            title={<SegHeader />}
          />
          <Divider />
          <CardContent lg={12} md={12} xl={12} xs={12}>
            <Grid container spacing={2}>
              <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={8} md={8} xl={8} xs={6}>
                  <SelectValidator
                    className={classes.textField}
                    variant="outlined"
                    id="idEmpresa"
                    label="Empresa"
                    onChange={handleChange('idEmpresa')}
                    value={props.obraAvaliacao.idEmpresa}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                  >
                    <MenuItem disabled value="">
                      <em>Empresa *</em>
                    </MenuItem>
                    {empresas?.map((row, index) => (
                      <MenuItem key={index} value={row.idEmpresa}>
                        {row.empresa}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={6}>
                  <FormLabel>
                    <Typography variant="subtitle1">Dê uma Nota de 1 a 5 pelo serviço prestado *</Typography>
                  </FormLabel>
                  <StarRating props={props} ratingClear={ratingClear} />
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    multiline
                    minRows={3}
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="descricao"
                    label="Descrição"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('descricao')}
                    value={props.obraAvaliacao.descricao || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                * Campos obrigatórios
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12} style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlgin: 'right',
              justifyContent: 'right'
            }}>
              <CustomButton
                variant="outlined"
                onClick={() => handleClean()}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                type="submit"
                variant="contained"
                dark={true}
                disabled={props.obraAvaliacao.isLoading || props.obraAvaliacao.valorReferencia == '2' || open}
                startIcon={<SaveIcon />}
                endIcon={props.obraAvaliacao.isLoading || open && <CircularProgress size={24} />}
              >
                {props.obraAvaliacao.id ? (props.obraAvaliacao.isLoading ? 'Atualizando Avaliação...' : 'Atualizar') : (props.obraAvaliacao.isLoading ? 'Salvando Avaliação...' : 'Salvar')}
              </CustomButton>
            </Grid>
          </CardActions>
        </ValidatorForm>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          <Input
            id="term"
            label="term"
            placeholder="Filtro"
            value={term}
            fullWidth
            onChange={handleChange('term')}
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Empresa</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Nota</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== obraAvaliacao && obraAvaliacao.length
              ? obraAvaliacao.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.empresa ? n.empresa.nomeFantasia : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.descricao ? n.descricao : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.nota ? <Stars mediaNota={n.nota} avaliacoes={1} /> : ''}
                    </TableCell>
                    <TableCell align="right">
                      <AccessControl
                        rule={'obrasAvaliacoes.edit'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Edit"
                            component="a"
                            onClick={() => handleEdit(n.id)}
                            size="small"
                          >
                            <Tooltip title={'Editar'}>
                              <Tooltip >
                                <EditIcon />
                              </Tooltip>
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'obrasAvaliacoes.delete'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Delete"
                            onClick={() => handleDelete(n.id)}
                            size="small"
                          >
                            <Tooltip title="Excluir">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                    </TableCell>
                  </TableRow >
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  <TableRow hover>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRegistros}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeLimit}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
      />

    </Grid>

  )
}

ObraAvaliacaoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(ObraAvaliacaoEdit))
)
export { connectedObraEditPage as ObraAvaliacaoEdit }
