import { STATUS_SERVICO } from "./constants";

export function getNomeEvento(evento) {
  try {
    if (evento.tipo == STATUS_SERVICO.LANCAMENTO) {
      return "EM LANÇAMENTO"
    } else if (evento.tipo == STATUS_SERVICO.PENDENTE) {
      return "PENDENTE";
    } else if (evento.tipo == STATUS_SERVICO.RECEBENDO_ORCAMENTOS) {
      return "RECEBENDO PROPOSTAS";
    } else if (evento.tipo == STATUS_SERVICO.ORCAMENTO_APROVADO) {
      return "EM EXECUÇÃO"; //ERA "ORÇAMENTO APROVADO"
    } else if (evento.tipo == STATUS_SERVICO.RECUSADO) {
      return "RECUSADA";
    } else if (evento.tipo == STATUS_SERVICO.CONCLUIDO) {
      return "CONCLUÍDA";
    } else if (evento.tipo == STATUS_SERVICO.AVALIADO) {
      return "AVALIADA";
    } else if (evento.tipo == STATUS_SERVICO.DESERTO) {
      return "DESERTA";
    } else if (evento.tipo == STATUS_SERVICO.SEM_SUCESSO) {
      return "SEM SUCESSO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getNomeUltimoEvento(ultimoEvento, obra, isLista) {
  try {
    if (ultimoEvento == STATUS_SERVICO.LANCAMENTO) {
      return 'EM LANÇAMENTO';
    } else if (ultimoEvento == STATUS_SERVICO.PENDENTE) {
      return 'PENDENTE';
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS && obra.credenciamento != true && isLista == true) {
      return 'RECEBENDO PROPOSTAS (' + obra?.obrasOrcamentos?.length + ')';
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS && obra.credenciamento == true) {
      return 'CREDENCIADA';
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS && isLista == false) {
      return 'RECEBENDO PROPOSTAS';
    } else if (ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO) {
      return "EM EXECUÇÃO"; //ERA "ORÇAMENTO APROVADO"
    } else if (ultimoEvento == STATUS_SERVICO.RECUSADO) {
      return "RECUSADA/EXCLUÍDA";
    } else if (ultimoEvento == STATUS_SERVICO.CONCLUIDO) {
      return "CONCLUÍDA";
    } else if (ultimoEvento == STATUS_SERVICO.AVALIADO) {
      return "AVALIADA";
    } else if (ultimoEvento == STATUS_SERVICO.PAUSADO) {
      return "PAUSADA";
    } else if (ultimoEvento == STATUS_SERVICO.DESERTO) {
      return "DESERTA";
    } else if (ultimoEvento == STATUS_SERVICO.SEM_SUCESSO) {
      return "SEM SUCESSO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getCor(ultimoEvento) {
  try {
    if (ultimoEvento == STATUS_SERVICO.LANCAMENTO) {
      return "#5e5e5e";
    } else if (ultimoEvento == STATUS_SERVICO.PENDENTE) {
      return "#9E9E9E";
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS) {
      return "#673AB7";
    } else if (ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO) {
      return "#2196F3";
    } else if (ultimoEvento == STATUS_SERVICO.RECUSADO) {
      return "#FF5722";
    } else if (ultimoEvento == STATUS_SERVICO.CONCLUIDO) {
      return "#4CAF50";
    } else if (ultimoEvento == STATUS_SERVICO.AVALIADO) {
      return "#FF9800";
    } else if (ultimoEvento == STATUS_SERVICO.PAUSADO) {
      return "#99FF99";
    } else if (ultimoEvento == STATUS_SERVICO.DESERTO) {
      return "#FF9999";
    } else if (ultimoEvento == STATUS_SERVICO.SEM_SUCESSO) {
      return "#FF2222";
    } else if (ultimoEvento == STATUS_SERVICO.CREDENCIADA) {
      return "#cc0066";
    } else {
      return "#000000";
    }
  } catch (err) {
    return "";
  }
}
