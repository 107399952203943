import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles'
import moment from 'moment'
import Typography from '@mui/material/Typography';
import {
  Backdrop,
  Card,
  Checkbox,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormLabel,
  IconButton,
  Paper,
  Grid,
  Input,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  ValidatorForm
} from 'react-material-ui-form-validator'
import { uploadFile } from '_services'
import {
  empenhoAction,
  obraAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import { Page } from 'components'
import SendIcon from '@mui/icons-material/Send';
import CustomButton from '../../components/CustomButton/CustomButton'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS } from '../../utils'
import { EmpenhoSummaryCard } from './empenhoSummaryCard.component';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const TableCellStyled = styled(TableCell)(() => ({
  backgroundColor: '#f2f2f2',
  paddingTop: 6,
  paddingBottom: 6,
  textAlign: 'center',
}));

const TableCellStyled2 = styled(TableCell)(() => ({
  backgroundColor: '#f2f2f2',
  cursor: 'pointer'
}));

const DivStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const EmpenhoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(undefined)
  const [fileNF, setFileNF] = useState(undefined)
  const [idEmpresas, setIdEmpresas] = useState([])
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [order] = useState('data');
  const [direction] = useState('desc');
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [obrasEmpenhos, setObrasEmpenhos] = useState(props.empenho.obrasEmpenhos);
  const obrasPorEmpresa = {};
  const [expandedEmpresaId, setExpandedEmpresaId] = useState(null);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const location = useLocation();
  const idEmpenho = location.state;

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleToggle = (empresaId) => {
    setExpandedEmpresaId(empresaId == expandedEmpresaId ? null : empresaId);
  };

  const obra = useSelector((state) => state.obra.obra)

  useEffect(() => {

    dispatch(empenhoAction.clear());

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdOrgao: idOrgao ? idOrgao : 0
    };

    dispatch(obraAction.getObraSemEmpenho(filterModel))
  }, []);

  useEffect(() => {

    dispatch(empenhoAction.clear());

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdOrgao: idOrgao ? idOrgao : 0
    };

    dispatch(obraAction.getObraSemEmpenho(filterModel))
  }, [idOrgao]);


  useEffect(() => {
    if (idOrgao == '') {
      toast.error('Selecione um órgão')
      history.push('/empenhos')
    }
  }, [idOrgao])

  useEffect(() => {
    if (fileNF && fileNF != undefined && fileNF != null) {
      setOpen(true)
      upload('caminhoNotaFiscal', fileNF)
    }
  }, [fileNF])

  const handleAddListaObrasEnviarEmpenho = (idObra, idEmpresaSelected, valor) => {
    setIdEmpresas([...idEmpresas, { idEmpresa: parseInt(idEmpresaSelected) }]);

    if (idObra) {
      if (obrasEmpenhos.some(ts => ts.idObra === parseInt(idObra))) {
        setObrasEmpenhos(obrasEmpenhos.filter(ts => ts.idObra !== parseInt(idObra)));
      } else {
        setObrasEmpenhos([...obrasEmpenhos, { idObra: parseInt(idObra), valor: valor }]);
      }
    }

    if (idEmpresaSelected) { //se já estiver selecionado, remove da lista
      if (idEmpresas.some(ts => ts.idEmpresa === parseInt(idEmpresaSelected))) {
        setIdEmpresas(idEmpresas.filter(ts => ts.idEmpresa !== parseInt(idEmpresaSelected)));
      } else {
        setIdEmpresas([...idEmpresas, { idEmpresa: parseInt(idEmpresaSelected) }]);
      }
    }
  }
  function calcValorTotalEmpenho() {
    const valorEmpenho = obrasEmpenhos?.reduce((subtotal, orc) => {
      return subtotal + (orc.valor || 0);
    }, 0);

    return valorEmpenho;
  };

  const isContainedListaObrasEnviarEmpenho = (idObra) => {
    if (idObra !== undefined) {
      return obrasEmpenhos.some(ts => ts.idObra === parseInt(idObra));
    }
    return false;
  }

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    if (file && file != undefined && file != null) {
      setOpen(true)
      upload('caminhoArquivo', file)
    }
  }, [file])

  for (const o of obra) {
    const empresaId = o.obrasOrcamentos[0]?.idEmpresa;
    if (!obrasPorEmpresa[empresaId]) {
      obrasPorEmpresa[empresaId] = [];
    }
    obrasPorEmpresa[empresaId].push(o);
  }

  const upload = (prop, fileUpload) => {
    uploadFile(fileUpload)
      .then((response) => {
        setTimeout(function () {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(empenhoAction.onChangeProps(prop, event2))
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }


  const returnNameArquivoEmpenho = () => {
    if (idEmpenho && props.empenho?.caminhoArquivo && props.empenho?.caminhoArquivo != undefined) {
      const pathArray = props.empenho?.caminhoArquivo.split('/');
      const nomeArquivo = pathArray[pathArray.length - 1];
      return nomeArquivo;
    }
  }

  const returnNameArquivoNF = () => {
    if (idEmpenho && props.empenho?.caminhoNotaFiscal && props.empenho?.caminhoNotaFiscal != undefined) {
      const pathArray = props.empenho?.caminhoNotaFiscal.split('/');
      const nomeArquivo = pathArray[pathArray.length - 1];
      return nomeArquivo;
    }
  }

  const handleChangeFileNotaFiscal = (e) => {
    setFileNF(e.target.files[0])
  }

  const handleOpenArquivo = (link) => {
    window.open(link, '_blank');
  }

  //verifica se todas as empresas selecionadas são iguais
  function areObjectsEqual(objArray) {
    if (objArray.length === 1) {
      return true; // Se houver apenas um objeto, eles são considerados iguais
    }

    const empresaIds = objArray.map(obj => obj.idEmpresa);

    // Verificar se todos os ids de empresa são iguais
    return empresaIds.every(id => id === empresaIds[0]);
  }

  const handleSubmit = () => {

    if (areObjectsEqual(idEmpresas)) { //valida se todas as empresas selecionadas são iguais
      if (obrasEmpenhos && obrasEmpenhos.length > 0) {
        let payload = {
          caminhoArquivo: props.empenho.caminhoArquivo,
          caminhoNotaFiscal: props.empenho.caminhoNotaFiscal,
          idOrgao: idOrgao,
          obrasEmpenhos: obrasEmpenhos,
          valor: calcValorTotalEmpenho(),
          idEmpresa: idEmpresas && idEmpresas[0].idEmpresa
        }

        dispatch(empenhoAction.createEmpenho(payload))

      } else {
        toast.error('É necessário selecionar pelo menos uma obra para prosseguir')
      }
    } else {
      toast.error('Somente é permitido criar um empenho com obras de uma mesma empresa')
    }

  };

  const handleEdit = () => {
    if (idEmpenho) {
      let payload = {
        id: idEmpenho,
        caminhoArquivo: props.empenho.caminhoArquivo,
        caminhoNotaFiscal: props.empenho.caminhoNotaFiscal,
        idOrgao: idOrgao,
        ordensServicosEmpenhos: props.empenho.ordensServicosEmpenhos,
        idEmpresa: props.empenho.idEmpresa
      }

      dispatch(empenhoAction.editEmpenhoInfo(idEmpenho, payload))

    }
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Empenho'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Empenho'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Empenhos - Editar Empenho'
          : 'Gerência de Empenhos - Novo Empenho'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          {(params.id || props.empenho.id) &&
            <EmpenhoSummaryCard idEmpenho={parseInt(params.id)} />
          }
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formEmpenho"
                onSubmit={(event) => !idEmpenho ? handleSubmit(event) : handleEdit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Empenhos"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                {!idEmpenho ?
                  <>
                    <Divider />
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={6}>
                                  <Typography variant="h6" align="center" style={{ marginBottom: 3 }}>
                                    <b>Selecione a(s) obra(s) para a(s) qual(is) você deseja enviar um empenho</b>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell><strong>Id</strong></TableCell>
                                <TableCell><strong>Título</strong></TableCell>
                                <TableCell><strong>Descritivo</strong></TableCell>
                                <TableCell><strong>Órgão</strong></TableCell>
                                <TableCell><strong>Data de Cadastro</strong></TableCell>
                                <TableCell align="right"><strong>Ações</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(obrasPorEmpresa).length > 0 ? (
                                Object.keys(obrasPorEmpresa).map((empresaId) => {
                                  const obrasEmpresa = obrasPorEmpresa[empresaId];
                                  const empresaNome = obrasEmpresa[0]?.obrasOrcamentos[0]?.empresa?.razaoSocial?.toUpperCase();
                                  const isExpanded = expandedEmpresaId == empresaId;

                                  return (
                                    <React.Fragment key={empresaId}>
                                      <TableRow>
                                        <TableCellStyled colSpan={5}>
                                          <DivStyled>
                                            <WorkOutlineRoundedIcon sx={{ marginRight: 2 }} />
                                            <span>{empresaNome}</span>
                                          </DivStyled>
                                        </TableCellStyled>
                                        <Tooltip title="Expandir obras da empresa">
                                          <TableCellStyled2 align="right">
                                            <ExpandMoreIcon onClick={() => handleToggle(empresaId)} />
                                          </TableCellStyled2>
                                        </Tooltip>
                                      </TableRow>
                                      {isExpanded &&
                                        obrasEmpresa?.map((obra) => (
                                          <TableRow hover key={obra.id}>
                                            <TableCell>{obra.id}</TableCell>
                                            <TableCell>{obra.titulo ? obra.titulo.toUpperCase() : ''}</TableCell>
                                            <TableCell>{obra.descritivo ? obra.descritivo.toUpperCase() : ''}</TableCell>
                                            <TableCell>{obra.orgao.nome ? obra.orgao.nome.toUpperCase() : ''}</TableCell>
                                            <TableCell>{obra.dataCadastro ? moment(obra.dataCadastro).format("DD/MM/YYYY HH:mm") : ''}</TableCell>
                                            <TableCell align="right">
                                              <Checkbox
                                                onClick={() => handleAddListaObrasEnviarEmpenho(obra.id, obra.obrasOrcamentos[0]?.idEmpresa, obra.obrasOrcamentos[0].valor)}
                                                color="primary"
                                                size="small"
                                                checked={isContainedListaObrasEnviarEmpenho(obra.id)}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <TableRow hover key={1}>
                                  <TableCell component="th" scope="row" align="center" colSpan={6}>
                                    Não há obras aptas a receber empenhos
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </CardContent>
                  </>
                : null}
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12} align="center">
                      <Backdrop className={classes.backdrop} open={open}>
                        <Grid
                          container
                          spacing={3}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <CircularProgress color="primary" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h4" color="textPrimary">
                              Enviando arquivo...
                            </Typography>
                          </Grid>
                        </Grid>
                      </Backdrop>
                      <FormLabel component="legend">Arquivo do Empenho</FormLabel>
                      <Input
                        id="caminhoArquivo"
                        className={classes.textField}
                        label="Arquivo do Empenho"
                        onChange={e => handleChangeFile(e)}
                        type="file"
                        placeholder="Enviar empenho"
                        formControl
                        margin="dense"
                      />
                      {returnNameArquivoEmpenho() ? <Typography className="left" style={{ fontSize: 12, marginTop: 3, cursor: 'pointer' }} onClick={() => handleOpenArquivo(props.empenho.caminhoArquivo)}>Arquivo atual: {returnNameArquivoEmpenho()}</Typography> : ''}
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12} align="center">
                      <Backdrop className={classes.backdrop} open={open}>
                        <Grid
                          container
                          spacing={3}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <CircularProgress color="primary" />
                          </Grid>
                          <Grid item>
                            <Typography variant="h4" color="textPrimary">
                              Enviando arquivo...
                            </Typography>
                          </Grid>
                        </Grid>
                      </Backdrop>
                      <FormLabel component="legend">Arquivo da NF</FormLabel>
                      <Input
                        id="caminhoNotaFiscal"
                        className={classes.textField}
                        label="Arquivo da NF"
                        placeholder='Arquivo da NF'
                        onChange={e => handleChangeFileNotaFiscal(e)}
                        type="file"
                        formControl
                        margin="dense"
                      />
                      {returnNameArquivoNF() ? <Typography className="left" style={{ fontSize: 12, marginTop: 3, cursor: 'pointer' }} onClick={() => handleOpenArquivo(props.empenho.caminhoNotaFiscal)}>Arquivo atual: {returnNameArquivoNF()}</Typography> : ''}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Somente são exibidas na lista as obras já concluídas e avaliadas
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlign: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/empenhos')}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      dark={true}
                      type="submit"
                      disabled={props.empenho.isLoading}
                      startIcon={<SendIcon />}
                      endIcon={props.empenho.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.empenho.isLoading ? 'Atualizando empenho...' : 'Atualizar') : (props.empenho.isLoading ? 'Enviando empenho...' : 'Enviar empenho')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid >
        {
          (modalHelp ?
            <HelpModal
              toggle={toggleModalHelp}
              modal={modalHelp}
              props={props}
              tela={MANUAIS_CATEGORIAS.CADASTRO_EMPENHO}
            />
            :
            null
          )
        }
      </Grid >
    </Page >
  )
}

EmpenhoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedEmpenhoEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(EmpenhoEdit))
)
export { connectedEmpenhoEditPage as EmpenhoEdit }
