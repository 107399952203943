const initialState = {
  empresaCidade: [],
  id: '',
  isLoading: false,
  idCidade: '',
  idEmpresa: '',
  excluido: null,
  empresa: {}
}

export function empresaCidade(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_EMPRESA_CIDADE_SUCCESS':
      return {
        ...state,
        idEmpresa: '',
        empresaCidade: [
          ...state.empresaCidade,
          action.empresaCidade
        ]
      }
    case 'FETCHED_ALL_EMPRESA_CIDADE':
      return {
        ...state,
        empresaCidade: action.empresaCidade
      }
    case 'EMPRESA_CIDADE_DETAIL':
      return {
        ...state,
        id: action.id,
        idCidade: action.idCidade,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        empresa: action.empresa
      }
    case 'EMPRESA_CIDADE_UPDATED':
      return state
    case 'EMPRESA_CIDADE_ADD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EMPRESA_CIDADE_ADD_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'EMPRESA_CIDADE_ADD_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_SUCCESS':
      return {
        ...state,
        isLoading: false
      }
    case 'USUARIO_ESTABELECIMENTO_DELETE_FAILURE':
      return {
        ...state,
        isLoading: false
      }
    case 'EMPRESA_CIDADE_CLEAR':
      return initialState
    case 'EMPRESA_CIDADE_CLEAR_ALL':
      return {
        ...state,
        empresaCidade: []
      }
    case 'EMPRESA_CIDADE_CLEAR_SELECTED':
      return {
        ...state,
        id: '',
        isLoading: false,
        idCidade: '',
        idEmpresa: '',
        excluido: null,
        empresa: {}
      }
    case 'CHANGE_DETAILS_EMPRESA_CIDADE':
      return {
        ...state,
        id: action.id,
        idCidade: action.idCidade,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        empresa: action.empresa
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        empresaCidade: action.user.usuariosEmpresas
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        empresaCidade: action.user.usuariosEmpresas
      }
    case 'OPEN_MODAL_REQUEST_CADASTRO_EMPRESA_CIDADE':
      return {
        ...state,
        openModalCadastroEmpresaCidade: action.open
      }
    case 'OPEN_MODAL_REQUEST_CADASTRO_TIPO_EMPRESA':
      return {
        ...state,
        openModalCadastroTipoEmpresa: action.open
      }
    default:
      return state
  }
}
