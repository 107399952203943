import { authentication } from './auth.reducer'
import { cidade } from './cidade.reducer'
import { convocacao } from './convocacao.reducer'
import { dashboard } from './dashboard.reducer'
import { estado } from './estado.reducer'
import { empresa } from './empresa.reducer'
import { empresaAnexo } from './empresaAnexo.reducer'
import { empresaCidade } from './empresaCidade.reducer'
import { empresaOrgao } from './empresaOrgao.reducer'
import { insumo } from './insumo.reducer'
import { log } from './log.reducer'
import { manual } from './manual.reducer'
import { notificacao } from './notificacao.reducer'
import { notificacaoFiltro } from './notificacaoFiltro.reducer'
import { notificacaoUsuario } from './notificacaoUsuario.reducer'
import { notificacaoTeste } from './notificacaoTeste.reducer'
import { orgao } from './orgao.reducer'
import { obra } from './obra.reducer'
import { obraAnexo } from './obraAnexo.reducer'
import { obraAvaliacao } from './obraAvaliacao.reducer'
import { empenho } from './empenho.reducer'
import { obraCobranca } from './obraCobranca.reducer'
import { obraCoordenada } from './obraCoordenada.reducer'
import { obraMedicao } from './obraMedicao.reducer'
import { obraMedicaoEtapaItemAnexo } from './obraMedicaoEtapaItemAnexo.reducer'
import { obraEtapa } from './obraEtapa.reducer'
import { obraEtapaItem } from './obraEtapaItem.reducer'
import { obraOcorrenciaAnexo } from './obraOcorrenciaAnexo.reducer'
import { obraOcorrencias } from './obraOcorrencias.reducer'
import { obraOrcamento } from './obraOrcamento.reducer'
import { obraOrcamentoItem } from './obraOrcamentoItem.reducer'
import { parametro } from './parametro.reducer'
import { permissao } from './permissao.reducer'
import { permissaoTipoUsuario } from './permissaoTipoUsuario.reducer'
import { permissaoUsuario } from './permissaoUsuario.reducer'
import { relatorio } from './relatorio.reducer'
import { subOrgao } from './subOrgao.reducer'
import { tabelaInsumo } from './tabelaInsumo.reducer'
import { tabelaInsumoAno } from './tabelaInsumoAno.reducer'
import { tabelaInsumoMes } from './tabelaInsumoMes.reducer'
import { tipoAnexo } from './tipoAnexo.reducer'
import { tipoUsuario } from './tipoUsuario.reducer'
import { tipoServico } from './tipoServico.reducer'
import { tipoObra } from './tipoObra.reducer'
import { unidade } from './unidade.reducer'
import { usuario } from './usuario.reducer'
import { usuarioOrgao } from './usuarioOrgao.reducer'
import { usuarioEmpresa } from './usuarioEmpresa.reducer'

import { combineReducers } from 'redux'

import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  authentication,
  cidade,
  convocacao,
  dashboard,
  estado,
  empenho,
  empresa,
  empresaAnexo,
  empresaCidade,
  empresaOrgao,
  insumo,
  log,
  manual,
  notificacao,
  notificacaoFiltro,
  notificacaoUsuario,
  notificacaoTeste,
  obra,
  obraAnexo,
  obraAvaliacao,
  obraCobranca,
  obraCoordenada,
  obraMedicao,
  obraMedicaoEtapaItemAnexo,
  obraEtapa,
  obraEtapaItem,
  obraOcorrenciaAnexo,
  obraOcorrencias,
  obraOrcamento,
  obraOrcamentoItem,
  orgao,
  parametro,
  permissao,
  permissaoTipoUsuario,
  permissaoUsuario,
  relatorio,
  subOrgao,
  tabelaInsumo,
  tabelaInsumoAno,
  tabelaInsumoMes,
  tipoAnexo,
  tipoServico,
  tipoUsuario,
  tipoObra,
  unidade,
  usuario,
  usuarioOrgao,
  usuarioEmpresa,
  form: formReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    const { routing } = state
    state = { routing }
  }
  return appReducer(state, action)
}
export default rootReducer
