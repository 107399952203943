import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { obraAction } from '_actions'
import { stringUtils } from 'utils'

export const obraOrcamentoAction = {
  getObraOrcamento,
  getObraOrcamentoSelect,
  getObraOrcamentoById,
  onChangeProps,
  editObraOrcamentoInfo,
  editObraOrcamentoAprova,
  editObraOrcamentoRecusa,
  createObraOrcamento,
  createAndApproveObraOrcamento,
  deleteObraOrcamentoById,
  revert,
  clear,
  clearDetail,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getObraOrcamento(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraOrcamentosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraOrcamentoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'obrasOrcamentos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObraOrcamentosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraOrcamento(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasOrcamentos'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/obrasOrcamentos')
        } else {
          // toast.error('Oops! Erro ao cadastrar Obra! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar Obra! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_ORCAMENTO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_ORCAMENTO_FAILURE', error }
  }
}

function createAndApproveObraOrcamento(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasOrcamentos/credenciamento'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(obraAction.getObraById(payload.idObra))
          toast.success('Feito! Cadastro realizado com sucesso!')
          dispatch(clear())
        }
        dispatch(notIsLoading())
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_ORCAMENTO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_ORCAMENTO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_ORCAMENTO_CLEAR'
  }
}

function editObraOrcamentoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasOrcamentos/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar Orçamento! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_ORCAMENTO_FAILURE', error }
  }
}

function editObraOrcamentoAprova(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentos/aprova';
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          toast.success('Feito! Orçamento aprovado com sucesso!')
          dispatch(notIsLoading())
          history.push('/obras')
        } else {
          dispatch(notIsLoading())
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_ORCAMENTO_FAILURE', error }
  }
}

function editObraOrcamentoRecusa(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentos/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/obras')
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }
}

function request(payload) {
  return { type: 'EDIT_OBRA_ORCAMENTO_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_OBRA_ORCAMENTO_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_OBRA_ORCAMENTO_FAILURE', error }
}


function getObraOrcamentoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasOrcamentos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObraOrcamentosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props == 'obrasOrcamentosItens') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraOrcamentoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObraOrcamentosDetails())
      dispatch(notIsLoading())
      history.push('/obrasOrcamentos')
      dispatch(getObraOrcamento(filterModel))
    })
      .catch((err) => {
        dispatch(notIsLoading())
      })
  }
}

function revert(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentos/reverterOrcamento/' + id
    crudService.put(apiEndpoint).then((response) => {
      dispatch(getObraOrcamento(filterModel))
      dispatch(notIsLoading())
      history.push('/obras')
    })
      .catch((err) => {
        dispatch(notIsLoading())
      })
  }
}

export function changeObraOrcamentosList(obraOrcamento, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_ORCAMENTO',
    obraOrcamento: obraOrcamento,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_ORCAMENTO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObraOrcamentosDetails(obraOrcamento) {
  return {
    type: 'OBRA_ORCAMENTO_DETAIL',
    id: obraOrcamento.id,
    data: obraOrcamento.data,
    prazo: obraOrcamento.prazo,
    diasPrazo: obraOrcamento.diasPrazo,
    valor: obraOrcamento.valor,
    statusOrcamento: obraOrcamento.statusOrcamento,
    idOrgao: obraOrcamento.idOrgao,
    idObra: obraOrcamento.idObra,
    obra: obraOrcamento.obra,
    idEmpresa: obraOrcamento.idEmpresa,
    empresa: obraOrcamento.empresa,
    excluido: obraOrcamento.excluido,
    observacoes: obraOrcamento.observacoes,
    obrasOrcamentosItens: obraOrcamento.obrasOrcamentosItens
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_ORCAMENTO_CREATED_SUCCESSFULLY'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_ORCAMENTO_CLEAR_DETAIL'
  }
}

export function deleteObraOrcamentosDetails() {
  return {
    type: 'DELETED_OBRA_ORCAMENTO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_ORCAMENTO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_ORCAMENTO_NOTISLOADING'
  }
}
