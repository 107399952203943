import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import moment from 'moment'
import CustomButton from '../../components/CustomButton/CustomButton'
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { phoneMask } from '../../utils'
import { AccessControl } from '../../components/Utils/AccessControl'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TablePagination,
  Typography
} from '@mui/material'
import {
  empresaAction
} from '../../_actions'
import { ObraConvocacaoObservacaoModal } from './obraConvocacaoObservacaoModal.component'

export const ObraConvocacaoModal = ({ modal, toggle, props, idObra, idOrgao }) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()

  const { classes, className, obraEtapa, ...rest } = props
  const { match: { params } } = props;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [idEmpresa, setIdEmpresa] = useState('');
  const [aceitar, setAceitar] = useState(false);

  const [modalConvocacao, setModalConvocacao] = useState(false);
  const toggleModalConvocacao = () => setModalConvocacao(!modalConvocacao);

  const handleClose = () => {
    toggle();
  }

  const { empresa } = useSelector((state) => state.empresa)
  const { totalRegistros } = props.empresa
  const { isLoading } = props.empresa

  useEffect(() => {

    let filterModel = {
      Page: page,
      Limit: limit,
      IdOrgao: idOrgao
    };

    dispatch(empresaAction.getEmpresaConvocacao(filterModel))
  }, [page, limit])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModalObservacoes = (idEmpresa, aprovar) => {
    setIdEmpresa(idEmpresa)
    setAceitar(aprovar)
    toggleModalConvocacao();
  }

  const handleChangeStatusConvocacao = (idEmpresa, aprovar) => {
    confirm({
      title: `Você deseja ${aprovar ? `aceitar` : `recusar`} esta convocação?`,
      content: 'Esta operação é irreversível',
      confirmationText: aprovar ? 'Sim, aceitar!' : 'Sim, recusar!',
      cancellationText: 'Não!',
      allowOutsideClick: false,
    }).then(() => {
      handleOpenModalObservacoes(idEmpresa, aprovar);
    });
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>Convocar empresa</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='lg'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Empresas"
              title={<SegHeader />}
            />
          </div>
          <Divider />
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Razão Social</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Última Convocação</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empresa && undefined !== empresa && empresa.length
                    ? empresa.map((n, index) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.razaoSocial ? n.razaoSocial : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.email ? n.email : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.telefone ? phoneMask(n.telefone) : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.convocacoes && n.convocacoes.length > 0 ? moment(n.convocacoes[0].data).format("DD/MM/yyyy HH:mm") : '-'}
                          </TableCell>
                          <TableCell align="right">
                            {/* somente exibe na primeira linha*/}
                            {index == 0 && page == 0 ?
                              <>
                                <AccessControl
                                  rule={'convocacoes.create'} //permissão necessária para acessar conteúdo
                                  yes={() => (
                                    <CustomButton
                                      variant="outlined"
                                      onClick={() => handleChangeStatusConvocacao(n.id, true)}
                                      disabled={props.obra.isLoading}
                                      startIcon={<DoneIcon />}
                                      endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                                    >
                                      Aceitar
                                    </CustomButton>
                                  )}
                                />
                                <AccessControl
                                  rule={'convocacoes.create'} //permissão necessária para acessar conteúdo
                                  yes={() => (
                                    <CustomButton
                                      variant="outlined"
                                      onClick={() => handleChangeStatusConvocacao(n.id, false)}
                                      disabled={props.obra.isLoading}
                                      startIcon={<CloseIcon />}
                                      endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                                    >
                                      Recusar
                                    </CustomButton>
                                  )}
                                />
                              </>
                              : null
                            }
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </CardContent>
        </Card>
      </DialogContent>

      {
        (modalConvocacao ?
          <ObraConvocacaoObservacaoModal
            toggle={toggleModalConvocacao}
            modal={modalConvocacao}
            props={props}
            aceitar={aceitar}
            idObra={idObra}
            idEmpresa={idEmpresa}
            idOrgao={idOrgao}
          />
          :
          null
        )
      }
    </Dialog >
  )
}
