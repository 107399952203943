import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { connect, useDispatch, useSelector } from 'react-redux'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {
  Badge,
  AppBar,
  Fade,
  Toolbar,
  Hidden,
  IconButton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableFooter
} from '@mui/material'
import { formatRelativeTime } from 'utils';
import { withStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu'
import InputIcon from '@mui/icons-material/Input'
import { authActions, obraAction, obraOcorrenciasAction } from '../../../../_actions'
import { withRouter } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { SelectOrgao } from './components'
import logo from '../../../../img/raiarWhite.png'

const styles = (theme) => ({
  root: {
    boxShadow: 'none'
  },
  topBar: {
    color: 'inherit',
    textDecoration: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const SpanStyled = styled('span')({
  borderRadius: 8,
  paddingTop: 2,
  paddingBottom: 1,
  paddingRight: 3,
  paddingLeft: 3,
  color: '#fff',
  fontSize: 10,
  fontWeight: 400,
  marginRight: 3
});

const ButtonStyled = styled('button')({
  paddingLeft: 4,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 4,
  fontSize: 10,
  border: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  color: '#000'
});

const ButtonStyledCenter = styled('button')({
  padding: '10px 20px',
  fontSize: '14px',
  border: 'none',
  cursor: 'pointer',
  backgroundColor: '#013A80', // Cor principal do aplicativo
  color: '#fff', // Contraste com o fundo
  borderRadius: '8px', // Bordas arredondadas
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para dar destaque
  display: 'block',
  margin: '20px auto', // Centraliza o botão
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: '#0250B0', // Cor de fundo ao passar o mouse
  },
});

const TableStyled = styled(Table)(() => ({
  marginBottom: 8,
  border: 'none'
}));

const ToolTipFormatted = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 550,
    fontSize: 9,
    border: '1.2px solid #000',
  },
}));

const Topbar = ({ staticContext, theme, ...props }) => {
  const { className, onSidebarOpen, ...rest } = props
  const { classes } = props
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch()

  const logout = () => {
    dispatch(authActions.logout())
  }

  const { obraOcorrencias } = useSelector(
    (state) => state?.obraOcorrencias
  );

  const handleOpenLastsEvents = () => {
    dispatch(obraOcorrenciasAction.getObraOcorrenciasSistema());
    setOpen(!open);
  };

  const handleNavigation = (event, idObra) => {
    event.preventDefault();
    if (idObra) {
      window.open(`/obra/${idObra}`, '_blank'); //abre em uma nova aba
      dispatch(obraAction.getObraById(idObra));
      dispatch(obraOcorrenciasAction.getObraOcorrenciasSistema());
    }
  };

  const renderTooltip = () => {
    return (
      <ToolTipFormatted
        open={open}
        onClose={() => setOpen(false)}
        leaveTouchDelay
        leaveDelay={2000}
        onMouseClick={() => setOpen(true)}
        placement="bottom-end"
        TransitionComponent={Fade}
        title={
          obraOcorrencias && obraOcorrencias.length > 0 ? (
            <>
            {obraOcorrencias.map((o, index) => (
                <TableContainer
                  key={index}
                  style={{
                    marginTop: index !== 0 && 3,
                    border: '1px solid #ddd',
                    borderRadius: 5,
                  }}>
                  <TableStyled size="small">
                    <TableHead>
                      {index === 0 && (
                        <TableRow key={1}>
                          <TableCell colSpan={4}>
                            <Typography
                              color="inherit"
                              align="center"
                              style={{ fontSize: 11 }}
                            >
                              <b>ÚLTIMOS EVENTOS</b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableHead>
                    <TableBody style={{ border: 'none' }}>
                      <TableRow key={o.id} style={{ border: 'none' }}>
                        <TableCell>
                          <Typography sx={{ fontSize: 11 }} color="inherit" align="left">
                          {o.data ? formatRelativeTime(o.data) : '-'} - {o.descricao && o.descricao != "" ? o.descricao?.length >= 120 ? (o.descricao?.substring(0, 120)?.toUpperCase() + '...') : o.descricao.toUpperCase() : '-'}
                          </Typography>
                          <SpanStyled style={{ backgroundColor: '#2196F3' }}>
                            <strong>#OBRA {o.idObra}</strong>
                          </SpanStyled>
                          {o.empresa && (
                            <SpanStyled style={{ backgroundColor: '#4CAF50' }}>
                              <strong>{o.empresa?.razaoSocial?.toUpperCase()}</strong>
                            </SpanStyled>
                          )}
                          {o.orgao && (
                            <SpanStyled style={{ backgroundColor: '#673AB7' }}>
                              <strong>{o.orgao?.nome?.toUpperCase()}</strong>
                            </SpanStyled>
                          )}
                          {o.usuario && (
                            <SpanStyled style={{ backgroundColor: '#9E9E9E' }}>
                              <strong>{o.usuario?.nome?.toUpperCase()}</strong>
                            </SpanStyled>
                          )}
                          {o.obra?.obrasOrcamentos && o.descricao?.includes('Orçamento aprovado') && o.obra?.obrasOrcamentos[0].status === '1' && (
                            <SpanStyled style={{ backgroundColor: '#E68A00' }}>
                              <strong>R${' '}{o?.obra?.obrasOrcamentos[0]?.valor?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</strong>
                            </SpanStyled>
                          )}
                          {o.obra?.obrasCobrancas && o.descricao?.includes('Nova cobrança') && (
                            <SpanStyled style={{ backgroundColor: '#EF5350' }}>
                              <strong>R${' '}{o?.obra?.obrasCobrancas[0]?.valor?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</strong>
                            </SpanStyled>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <ButtonStyled onClick={(e) => handleNavigation(e, o.idObra)} size="small">
                            VER MAIS
                          </ButtonStyled>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </TableStyled>
                </TableContainer>
              ))
            }
            <ButtonStyledCenter onClick={() => window.open(`/relatorioOcorrenciasObra`, '_blank')} size="small">
              VER TODAS AS OCORRÊNCIAS
            </ButtonStyledCenter>
            </>
          ) : (
            <Typography color="inherit" align="center" style={{ fontSize: 11, padding: 7 }}>
              Não há registros para exibir
            </Typography>
          )}
      >
        <IconButton style={{ marginLeft: 10 }} color="inherit" onClick={handleOpenLastsEvents}>
          <NotificationsNoneOutlinedIcon />
        </IconButton>
      </ToolTipFormatted>
    );
  };

  return (
    <AppBar
      style={{ backgroundColor: '#015192' }}
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/" className={classes.topBar}>
          <img
            width={140}
            alt="Logo Raiar"
            src={logo}
          />
        </RouterLink>
        <div style={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <div className={classes.selectLoja}>
            <SelectOrgao disabled={false} />
          </div>
          {(authActions.isGestor() || authActions.isPrefeitura() || authActions.isEmpresa()) &&
            renderTooltip()
          }
          <Tooltip title="Sair">
            <IconButton
              style={{ marginLeft: 10 }}
              color="inherit"
              onClick={logout}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            left: 0,
            right: 0
          }}>
            <SelectOrgao disabled={false} />
          </div>
          {(authActions.isGestor() || authActions.isPrefeitura() || authActions.isEmpresa()) &&
            renderTooltip()
          }
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  type: PropTypes.string
}

const mapStateToProps = (state) => {
  return state
}
const TopbarPage = withRouter(
  //connect(mapStateToProps, null, null, {
  //  pure: false
  //})(withStyles(styles)(Topbar))
  connect(mapStateToProps, null, null)(withStyles(styles)(Topbar))
)
export default TopbarPage
