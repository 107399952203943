import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  convocacaoAction
} from '../../_actions'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

export const ObraConvocacaoObservacaoModal = ({ modal, toggle, props, idEmpresa, aceitar, idObra, idOrgao }) => {

  const formRef = React.createRef(null);

  const [observacoes, setObservacoes] = useState('');
  const [diasPrazo, setDiasPrazo] = useState();

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const handleClose = () => {
    toggle();
  }

  const handleSubmit = () => {
    let payload = {
      idEmpresa: idEmpresa,
      idObra: idObra,
      observacoes: observacoes,
      aceita: aceitar
    }

    let payloadOrcamento = {
      idEmpresa: idEmpresa,
      idObra: idObra,
      diasPrazo: diasPrazo,
      observacoes: observacoes
    }

    dispatch(convocacaoAction.createConvocacao(payload, payloadOrcamento, idOrgao))
    toggle();
  }
  function InsertText() {
    return (
      <Typography variant="h5">
        <b>Convocar empresa para realizar o serviço</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='lg'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader={`Informar uma observação para a convocação`}
              title={<SegHeader />}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ paddingTop: 0, marginTop: 0 }}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="observacoes"
                    label="Observações *"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={(event) => setObservacoes(event.target.value)}
                    value={observacoes || ''}
                    variant="outlined"
                  />
                </Grid>
                {aceitar == true ?
                  <Grid item lg={3} md={3} xl={3} xs={12} align="center" style={{ paddingTop: 0, marginTop: 0 }}>
                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      type="number"
                      id="diasPrazo"
                      label="Prazo de Execução da Obra (dias) *"
                      onChange={(e) => setDiasPrazo(e.target.value)}
                      margin="normal"
                      value={diasPrazo}
                      variant="outlined"
                      validators={['required']}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        min: 0
                      }}
                      errorMessages={['Campo obrigatório!']}
                    />
                  </Grid>
                  : null
                }
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.convocacao.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.convocacao.isLoading && <CircularProgress size={24} />}
                >
                  {props.convocacao.isLoading ? 'Salvando convocação...' : 'Salvar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
