import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Input,
  Skeleton
} from '@mui/material'
import { notificacaoAction } from '_actions'
import { useDispatch, useSelector, connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { notificacaoUsuarioAction } from '../../_actions'
import { useDebounce } from 'use-debounce';
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ModalNotificacaoUsuarios = (props) => {

  const dispatch = useDispatch()

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order, setOrder] = useState('nome');
  const [direction, setDirection] = useState('asc');

  const { notificacaoUsuario, totalRegistros, isLoading } = useSelector(
    (state) => state.notificacaoUsuario
  )

  const { openModalNotificacaoUsuarios, loading } = useSelector(
    (state) => state.notificacao
  )

  const fetchData = (page, limit, term) => {

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdNotificacao: props.notificacao.id
    };

    dispatch(notificacaoUsuarioAction.getNotificacaoUsuario(filterModel, true));

  };

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, []);

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, [openModalNotificacaoUsuarios])

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {
  }, [notificacaoUsuario]);

  const handleClose = () => {
    dispatch(notificacaoAction.openModalNotificacaoUsuarios(false))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Dialog
      open={openModalNotificacaoUsuarios}
      onClose={handleClose}
      aria-labelledby="Descrição tipo de ingresso"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Listagem de usuários que receberão a notificação
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Input
                id="term"
                label="term"
                placeholder="Filtro"
                value={term}
                onChange={handleChange('term')}
              />
            </Grid>
            <br />
            <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'center', alignContent: 'center' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Abertura</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(undefined !== notificacaoUsuario && notificacaoUsuario.length
                      ? notificacaoUsuario.map((n) => {
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.usuario ? (n.usuario.nome ? n.usuario.nome : '') : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.usuario ? (n.usuario.email ? n.usuario.email : '') : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {(n.dataHoraAbertura ? moment(n.dataHoraAbertura).format("DD/MM/YYYY HH:mm") : '')}
                            </TableCell>
                          </TableRow>
                        )
                      })
                      :

                      (
                        isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                      )

                    )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalRegistros}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={limit}
                onRowsPerPageChange={handleChangeLimit}
              />
            </Grid>
          </>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          style={{ padding: 5 }}
          spacing={2}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={handleClose} variant="outlined"
              style={{ color: '#000', borderColor: '#000' }}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ModalNotificacaoUsuarios.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalNotificacaoUsuariosPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(ModalNotificacaoUsuarios))
)
export { connectedModalNotificacaoUsuariosPage as ModalNotificacaoUsuarios }
