import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { parametroAction } from '../../_actions'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import {
  Grid,
  IconButton,
  Typography,
  Tooltip
} from '@mui/material'
import { Page } from 'components'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS } from '../../utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  }
})

const LinksUteis = (props) => {

  const dispatch = useDispatch();
  const { classes } = props;

  const { parametro } = props;

  useEffect(() => {
    dispatch(parametroAction.getLinksUteis());
  }, []);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }


  const formattedParametro = useMemo(() => {
    return { __html: parametro?.valor?.replace('<a ', '<a target=_"blank" ') };
  }, [parametro.valor]);

  return (
    <Page className={classes.root} title="Links Úteis">
      <div>
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {parametro && undefined != parametro && parametro != null && (
            <Typography component="h1" variant="h5">
              Lista Gov.Br
            </Typography>
          )}
          <Grid item lg={1} md={1} xl={1} xs={12}>
            <IconButton
              aria-label="Ajuda"
              component="a"
              onClick={() =>
                handleOpenModalHelp()
              }
              size="small"
            >
              <Tooltip title={'Ajuda'}>
                <Tooltip>
                  <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                </Tooltip>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        <br />
        <div dangerouslySetInnerHTML={formattedParametro}></div>
      </div>
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.LINK_GOV}
          />
          :
          null
        )
      }
    </Page>
  );
}

const mapStateToProps = (state) => {
  return state;
}
const connectedLinksUteisPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(LinksUteis))
)
export { connectedLinksUteisPage as LinksUteis }
