import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { useConfirm } from 'material-ui-confirm'
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Switch,
  TextField,
  FormControlLabel
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  orgaoAction,
  cidadeAction,
  estadoAction,
  usuarioAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, cnpjMask, stringUtils, phoneMask } from '../../utils'
import { Page } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import logo from '../../img/raiarBlue.png'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const SwitchBlue = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#1C5585',
    }
  }
}));

const OrgaoUsuarioEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, orgao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const confirm = useConfirm()

  const [idOrgao, setIdOrgao] = useState(0);
  const [textOrgao, setTextOrgao] = useState('');

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)
  const [openOrgao, setOpenOrgao] = React.useState(false)

  const [valueOrgao, setValueOrgao] = React.useState(null);

  const { cidade } = useSelector(
    (state) => state.cidade
  )

  const estados = useSelector((state) => state.estado.estado)

  const options = cidade.map(u => ({ id: u.value, nome: u.text }));

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {

    dispatch(orgaoAction.clearUsuariosOrgaos())
    dispatch(estadoAction.getEstadoSelect())

    if (params.id) {
      dispatch(orgaoAction.getOrgaoById(params.id))
    } else {
      dispatch(orgaoAction.clear())
      dispatch(usuarioAction.clear())
      dispatch(orgaoAction.clearUsuariosOrgaos())
    }

    if (props.orgao.idEstado != undefined && props.orgao.idEstado !== 0 && props.orgao.idEstado !== '') {
      dispatch(cidadeAction.getCidadeByIdEstado(props.orgao.idEstado))
    }

    const interval = setInterval(() => {
      setReady(true)
    }, 1500);
    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    if (props.orgao.idCidade != 0 && params.id != undefined) {
      setValue(props.orgao.cidade)
    }
  }, [props.orgao.idCidade])

  useEffect(() => {
    if (props.orgao.idEstado) {
      dispatch(cidadeAction.getCidadeByIdEstado(props.orgao.idEstado))
    }
  }, [props.orgao.idEstado])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selected = cidade.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        cidadeAction.changeDetailsCidade(
          selected[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleChangeAutoCompleteOrgao = (obj) => {
    if (obj != null) {
      setIdOrgao(obj.id);
      setTextOrgao(obj.nome);
    }
    setOpenOrgao(false)
  }

  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoCompleteOrgao(valueOrgao)
    }
  }, [valueOrgao])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.orgao.orgaosOrgaos])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.orgao.usuariosOrgaos])

  const handleChange = (prop) => (event) => {
    dispatch(orgaoAction.onChangeProps(prop, event))
  }

  const handleChangeUsuario = (prop) => (event) => {
    dispatch(usuarioAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      nome: props.orgao.nome,
      idCidade: value ? value.id : 0,
      idEstado: props.orgao.idEstado,
      celular: props.orgao.celular && props.orgao.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      cnpj: props.orgao.cnpj && props.orgao.cnpj.split('.').join('').replace(/[-/.]/g, ''),
      email: props.orgao.email ? props.orgao.email.toLowerCase() : '',
      endereco: props.orgao.endereco,
      telefone: props.orgao.telefone && props.orgao.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      mapaPublico: props.orgao.mapaPublico,
      usuariosOrgaos: [
        {
          id: 0,
          idUsuario: 0,
          idOrgao: 0,
          usuario:
          {
            nome: props.usuario.nome,
            senha: props.usuario.senha,
            sobrenome: props.usuario.sobrenome,
            email: props.usuario.email.toLowerCase(),
            idTipoUsuario: 2,
            celular: props.usuario.celular && props.usuario.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
            matricula: props.usuario.matricula,
            telefone: props.usuario.telefone && props.usuario.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
          }
        }
      ]
    }

    dispatch(orgaoAction.createOrgaoApp(payload))

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Cadastro de Órgão Público'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={'Gerência de Órgãos - Novo Órgão'}
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formOrgao"
                onSubmit={(event) => handleSubmit(event)}
              >
                <img
                  width={140}
                  alt="Logo Raiar"
                  src={logo}
                  align='right'
                  style={{ paddingTop: 20, paddingRight: 20 }}
                />
                <CardHeader
                  subheader="Dados do Órgão"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.orgao.nome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idEstado"
                        label="Estado *"
                        value={props.orgao.idEstado}
                        onChange={handleChange('idEstado')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idEstado"
                      >
                        <MenuItem disabled value="">
                          <em>Estado *</em>
                        </MenuItem>
                        {!isEmpty(estados) &&
                          undefined !== estados &&
                          estados.length &&
                          estados.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={options}
                        margin="normal"
                        variant="outlined"
                        defaultValue={value}
                        isOptionEqualToValue={(option, value) => option.id == value.id}
                        getOptionLabel={option => option.nome}
                        renderInput={(params) => <TextField required {...params} label="Selecione uma cidade..." fullWidth />}
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="cnpj"
                        label="CNPJ"
                        margin="normal"
                        onChange={handleChange('cnpj')}
                        value={cnpjMask(props.orgao.cnpj) || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="endereco"
                        label="Endereço"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('endereco')}
                        value={props.orgao.endereco || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <FormControlLabel
                        control={
                          <SwitchBlue
                            checked={props.orgao.mapaPublico}
                            onChange={handleChange('mapaPublico')}
                          />
                        }
                        label="Permite a visualização pública da obra?"
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid container lg={12} md={12} xl={12} xs={12}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography component="h4" variant="subtitle" align="center">
                        Dados de Contato do Órgão
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          validators={['required', 'isEmail']}
                          fullWidth
                          label="E-mail *"
                          onInput={stringUtils.toInputLowerCase}
                          errorMessages={[
                            'Campo obrigatório!', 'E-mail inválido'
                          ]}
                          value={props.orgao.email || ''}
                          className={classes.textField}
                          onChange={handleChange('email')}
                          id="email"
                          name="email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="telefone"
                          label="Telefone"
                          margin="normal"
                          onChange={handleChange('telefone')}
                          value={phoneMask(props.orgao.telefone) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="celular"
                          label="Celular"
                          margin="normal"
                          onChange={handleChange('celular')}
                          value={phoneMask(props.orgao.celular) || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                  <Divider />
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Card>
                    <CardHeader
                      subheader="Dados do Usuário Gestor"
                      title={<Typography variant="h5"><b>Cadastro de Usuário</b></Typography>}
                    />
                    <Divider />
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="nome"
                            label="Nome *"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChangeUsuario('nome')}
                            value={props.usuario.nome || ''}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="sobrenome"
                            label="Sobrenome *"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChangeUsuario('sobrenome')}
                            value={props.usuario.sobrenome || ''}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="E-mail *"
                            value={props.usuario.email || ''}
                            className={classes.textField}
                            onInput={stringUtils.toInputLowerCase}
                            onChange={handleChangeUsuario('email')}
                            id="email"
                            name="email"
                            autoComplete="email"
                            validators={['required', 'isEmail']}
                            errorMessages={[
                              'Você precisa informar o e-mail',
                              'E-mail inválido'
                            ]}
                            disabled={props.usuario.id != 0}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="celular"
                            label="Celular "
                            margin="normal"
                            onChange={handleChangeUsuario('celular')}
                            value={phoneMask(props.usuario.celular) || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="telefone"
                            label="Telefone "
                            margin="normal"
                            onChange={handleChangeUsuario('telefone')}
                            value={phoneMask(props.usuario.telefone) || ''}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="matricula"
                            label="Matrícula"
                            type="number"
                            margin="normal"
                            onChange={handleChangeUsuario('matricula')}
                            value={props.usuario.matricula || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                          * Campos obrigatórios
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlgin: 'right',
                        justifyContent: 'right'
                      }}>
                        <Button
                          variant="outlined"
                          style={{ color: '#000', borderColor: '#000' }}
                          onClick={() => history.push('/login')}
                        >
                          Cancelar
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ backgroundColor: '#000' }}
                          disabled={props.orgao.isLoading}
                          startIcon={<SaveIcon />}
                          endIcon={props.orgao.isLoading && <CircularProgress size={24} />}
                        >
                          {props.orgao.isLoading ? 'Salvando orgao...' : 'Salvar'}
                        </Button>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page >
  )
}

const mapStateToProps = (state) => {
  return state
}

const connectedOrgaoUsuarioEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(OrgaoUsuarioEdit))
)
export { connectedOrgaoUsuarioEditPage as OrgaoUsuarioEdit }
