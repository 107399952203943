const initialState = {
  anchor: 'left',
  isLoading: false,
  convocacao: [],
  open: false,
  id: 0,
  data: '',
  observacoes: '',
  idEmpresa: 0,
  empresa: [],
  idObra: 0,
  obra: [],
  totalRegistros: 0,
  aceita: false
}

export function convocacao(state = initialState, action) {
  switch (action.type) {
    case 'CONVOCACAO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        data: action.data,
        observacoes: action.observacoes,
        idEmpresa: action.idEmpresa,
        empresa: action.empresa,
        idObra: action.idObra,
        obra: action.obra,
        aceita: action.aceita
      }
    case 'CONVOCACAO_UPDATED':
      return state
    case 'CONVOCACAO_CLEAR':
      return initialState
    case 'CONVOCACAO_CLEAR_ALL':
      return {
        ...state,
        convocacao: []
      }
    case 'FETCHED_ALL_CONVOCACAO':
      return {
        ...state,
        convocacao: action.convocacao,
        totalRegistros: action.totalRegistros
      }
    case 'CONVOCACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'CONVOCACAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'CONVOCACAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
