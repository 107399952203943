import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { empenhoAction } from '../../_actions'
import { styled } from '@mui/material/styles';
import moment from 'moment'
import PropTypes from 'prop-types'
import { getCor, getNomeUltimoEvento, MANUAIS_CATEGORIAS, history } from '../../utils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit'
import { uploadFile } from '_services'
import CardNumberEmpenhos from './cardNumberEmpenhos.component'
import {
  Backdrop,
  Button,
  CircularProgress,
  Checkbox,
  Fade,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Skeleton,
  Input,
  Grid,
  TablePagination,
  Typography
} from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';
import { ContextEmpenhosInfosProvider } from '../../contexts/ContextEmpenhosInfos';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  }
})

const ButtonStyled = styled(Button)(() => ({
  paddingTop: 2,
  paddingLeft: 2,
  paddingRight: 2,
  paddingBottom: 0,
  fontSize: 10,
  verticalAlign: 'middle',
  backgroundColor: '#1C5585',
  color: '#FFF',
  '&:hover': {
    backgroundColor: '#000',
    transition: '0.2s'
  },
}));

const TableStyled = styled(Table)(() => ({
  marginBottom: 8
}));

const SpanStyled = styled('span')({
  borderRadius: 8,
  paddingTop: 2,
  paddingBottom: 1,
  paddingRight: 3,
  paddingLeft: 3,
  color: '#fff',
  fontSize: 10,
  fontWeight: 400,
  marginRight: 3
});

const ToolTipFormatted = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 750,
    fontSize: 9,
    border: '1.2px solid #90b0cb',
  },
}));

const Empenho = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { empenho } = props.empenho
  const { isLoading } = props.empenho
  const { totalRegistros } = props.empenho

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('dataCadastro');
  const [direction] = useState('asc');

  const [pagarEmpenho, setPagarEmpenho] = useState(false)
  const [idEmpenho, setIdEmpenho] = useState(null)
  const [open, setOpen] = useState(false)

  const [ready,] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao)
      };

      dispatch(empenhoAction.getEmpenho(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {
    if (pagarEmpenho && idEmpenho) {
      realizarPagamentoEmpenho()
    }
  }, [props.empenho.caminhoNotaFiscal, pagarEmpenho]);

  useEffect(() => {

  }, [ready, props.empenho.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRealizaPagamento = (id) => {
    confirm({
      title: 'Você deseja marcar este empenho como pago?',
      description: returnUploadNF(),
      disabled: props.empenho.isLoading,
      confirmationText: props.empenho.isLoading ? 'Marcando como pago...' : 'Sim!',
      cancellationText: 'Não!'
    }).then(() => {
      setPagarEmpenho(true)
      setIdEmpenho(id)
    })
  }

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este empenho?',
      description: 'Essa operação é irreversível',
      disabled: props.empenho.isLoading,
      confirmationText: props.empenho.isLoading ? 'Excluindo empenho...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(empenhoAction.deleteEmpenhoById(id))
    })
  }

  const realizarPagamentoEmpenho = () => {
    if (idEmpenho && idEmpenho != 0) {
      dispatch(empenhoAction.editEmpenhoRealizaPagamento(idEmpenho, idOrgao, null, {
        caminhoNotaFiscal: props.empenho.caminhoNotaFiscal
      }));
    }
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const handleOpenArquivo = (link) => {
    window.open(link, '_blank');
  }

  const isItemPaid = (id) => {
    if (id != undefined) {
      return empenho?.some((n) => n.dataPagamento != null && n.id == id)
    }
  }

  const handleUploadNotaFiscal = (e) => {
    setOpen(true)
    var file = e.target.files[0]
    if (file) {
      uploadFile(file)
        .then((response) => {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(empenhoAction.onChangeProps('caminhoNotaFiscal', event2))
          setOpen(false)
        })
        .catch((err) => {
          console.log(err)
          setOpen(false)
        })
    }
  }

  function returnUploadNF() {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} align="left">
          <Backdrop className={classes.backdrop} open={open}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
            >
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
              <Grid item>
                <Typography variant="h4" color="textPrimary">
                  Enviando nota fiscal...
                </Typography>
              </Grid>
            </Grid>
          </Backdrop>
          <FormLabel component="legend">Enviar Nota Fiscal</FormLabel>
          <Input
            id="caminhoNotaFiscal"
            className={classes.textField}
            label="Arquivo da NF"
            placeholder='Arquivo da NF'
            onChange={handleUploadNotaFiscal}
            type="file"
            placeHolder="Enviar nota fiscal"
            formControl
            margin="dense"
          />
        </Grid>
      </Grid>
    )
  }

  //precisa somar o valor de todas as ordens de serviço dentro do empenho, pois pode ter
  //mais de uma por empenho
  const calcValorTotalEmpenho = (idEmpenho) => {
    return empenho?.reduce((total, em) => {
      if (em.id === idEmpenho) {
        const valorEmpenho = em.obrasEmpenhos?.reduce((subtotal, os) => {
          const valorOrcamento = os.obra?.obrasOrcamentos?.reduce((subSubtotal, orc) => {
            return subSubtotal + (orc.valor || 0);
          }, 0);
          return subtotal + valorOrcamento;
        }, 0);
        return total + valorEmpenho;
      }
      return total;
    }, 0);
  };

  return (
    <Page className={classes.root} title="Empenhos">
      <AccessControl
        rule={'empenhos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== empenho && empenho.length > 0 ? (
              <TitleContent
                rule={'empenhos.create'}
                length={empenho.length}
                subTitle={'GERÊNCIA DE EMPENHO'}
                title={'Lista de Empenhos Enviados'}
                href={'/empenho'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_EMPENHO}
              />
            ) : (
              <TitleContent
                rule={'empenhos.create'}
                length={0}
                subTitle={'GERÊNCIA DE EMPENHO'}
                title={'Lista de Empenhos Enviados'}
                href={'/empenho'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_EMPENHO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              <ContextEmpenhosInfosProvider>
                <Grid item xl={12} lg={12} md={12} xs={12} align="center">
                  <CardNumberEmpenhos />
                </Grid>
              </ContextEmpenhosInfosProvider>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Informações</TableCell>
                    <TableCell>Empenho</TableCell>
                    <TableCell>Nota Fiscal</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell>Data de Cadastro</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== empenho && empenho.length
                    ? empenho.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <ToolTipFormatted
                            placement="auto"
                            TransitionComponent={Fade}
                            title={
                              <Grid>
                                {n.obrasEmpenhos?.map((o, index) => (
                                  <TableStyled size="small" fullWidth>
                                    <TableHead>
                                      {index === 0 &&
                                        <TableRow>
                                          <TableCell colSpan={4}>
                                            <Typography color="inherit" align="center" style={{ fontSize: 11 }}><b>DETALHES DA(S) OBRA(S)</b></Typography>
                                            <Typography sx={{ fontSize: 10 }} color="inherit" align="center">
                                              <b>Empresa:</b>&nbsp;
                                              {o.obra?.obrasOrcamentos[0]
                                                ? o.obra?.obrasOrcamentos[0].empresa?.razaoSocial?.toUpperCase()
                                                : ''}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      }
                                    </TableHead>
                                    <React.Fragment key={o.obra.id}>
                                      <TableContainer style={{ marginTop: index != 0 && 3, border: '1px solid #ddd', borderRadius: 5 }}>
                                        <Table size="small" style={{ border: 0 }}>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>
                                                <Typography sx={{ fontSize: 10 }} color="inherit" align="left">
                                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <SpanStyled style={{ backgroundColor: getCor(o.obra.ultimoEvento) }}>{getNomeUltimoEvento(o.obra.ultimoEvento, o, true)}</SpanStyled>
                                                    <b>Título:</b>&nbsp;{o.obra?.titulo?.toUpperCase()}
                                                  </div>
                                                </Typography>
                                              </TableCell>
                                              <TableCell>
                                                <Typography sx={{ fontSize: 10 }} color="inherit" align="left">
                                                  <b>Descritivo:</b>&nbsp;{o.obra?.descritivo ? o.obra?.descritivo?.toUpperCase() : '-'}
                                                </Typography>
                                              </TableCell>
                                              <TableCell align="right">
                                                <ButtonStyled
                                                  onClick={() => window.open(`/obra/` + o.obra?.id, '_blank')}
                                                  size="small"
                                                >
                                                  Ver mais
                                                </ButtonStyled>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </React.Fragment>
                                  </TableStyled>
                                ))}
                              </Grid>
                            }
                          >
                            <TableCell component="th" scope="row" align="left">
                              <InfoOutlinedIcon style={{
                                cursor: 'pointer',
                                fontSize: '1.5em'
                              }} />
                            </TableCell>
                          </ToolTipFormatted>
                          <Tooltip title={n.caminhoArquivo && "Clique aqui para abrir o arquivo em uma nova aba"}>
                            <TableCell component="th" scope="row" style={{ cursor: n.caminhoArquivo ? 'pointer' : 'default' }} onClick={() => handleOpenArquivo(n.caminhoArquivo)}>
                              {n.caminhoArquivo ? <InsertDriveFileOutlinedIcon style={{
                                cursor: 'pointer',
                                fontSize: '1.5em'
                              }} /> : '-'}
                            </TableCell>
                          </Tooltip>
                          <Tooltip title={n.caminhoNotaFiscal && "Clique aqui para abrir o arquivo em uma nova aba"}>
                            <TableCell component="th" scope="row" style={{ cursor: n.caminhoNotaFiscal ? 'pointer' : 'default' }} onClick={() => handleOpenArquivo(n.caminhoNotaFiscal)}>
                              {n.caminhoNotaFiscal ? <InsertDriveFileOutlinedIcon style={{
                                cursor: 'pointer',
                                fontSize: '1.5em'
                              }} /> : '-'}
                            </TableCell>
                          </Tooltip>
                          <TableCell component="th" scope="row">
                            {n.orgao != null ? n.orgao.nome?.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.dataCadastro ? moment(n.dataCadastro).format("DD/MM/YYYY HH:mm") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            R$ {calcValorTotalEmpenho(n.id)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                          </TableCell>
                          <TableCell component="th" scope="row" className={classes.textField}>
                            {n.dataPagamento ? `PAGO EM ${moment(n.dataPagamento).format("DD/MM/YYYY HH:mm")}` : 'EM ABERTO'}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'empenhos.pay'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <Tooltip title={'Receber pagamento'}>
                                  <Tooltip >
                                    <Checkbox
                                      onClick={() => handleRealizaPagamento(n.id)}
                                      color="primary"
                                      size="small"
                                      disabled={isItemPaid(n.id)}
                                      checked={isItemPaid(n.id)}
                                    />
                                  </Tooltip>
                                </Tooltip>
                              )}
                            />
                            <AccessControl
                              rule={'empenhos.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/empenho/${n.id}`, n.id)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'empenhos.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>


                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={8} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover key={i}>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
Empenho.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedEmpenhoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(Empenho))
)
export { connectedEmpenhoPage as Empenho }
