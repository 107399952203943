import { crudService } from '../_services/'
import { stringUtils } from 'utils'
import { empresaAction, obraOrcamentoAction } from '_actions'
import { history } from '../utils'
import { toast } from 'react-toastify'

export const convocacaoAction = {
  getConvocacao,
  addConvocacao,
  getConvocacaoById,
  onChangeProps,
  editConvocacaoInfo,
  editConvocacaosDetails,
  createConvocacao,
  changeDetailsConvocacao,
  clear,
  clearAll,
  notIsLoading,
  isLoading
}

function getConvocacao(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'convocacoes' + stringUtils.serialize(filterModel);;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeConvocacaosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
        dispatch(empresaAction.getEmpresa(filterModel))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function createConvocacao(payload, payloadOrcamento, idOrgao) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'convocacoes/'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(createConvocacaoInfo())
          dispatch(empresaAction.getEmpresaConvocacao({ Page: 0, IdOrgao: idOrgao }))

          //só cria o orçamento se a convocação tiver sido aceita
          if (payload.aceita == true) {
            dispatch(obraOrcamentoAction.createAndApproveObraOrcamento(payloadOrcamento))

            toast.success('Convocação criada com sucesso!')
          } else {
            toast.success('Convocação recusada com sucesso!')
          }
          dispatch(clear())

          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(notIsLoading())
        //    toast.error('Oops! Erro ao cadastrar usuário! 😥')
      })
  }
}
function getConvocacaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'convocacoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editConvocacaosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function changeDetailsConvocacao(convocacao) {
  return (dispatch) => {
    if (convocacao) {
      dispatch(onChangeDetailsConvocacao(convocacao))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsConvocacao(convocacao) {
  return [
    {
      type: 'CHANGE_DETAILS_CONVOCACAO',
      id: convocacao.id,
      nome: convocacao.nome
    }
  ]
}

function editConvocacaoInfo(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'convocacoes/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/convocacoes')
        } else {
          //   toast.err('Oops! Erro ao cadastrar convocacao! 😥')
        }
      })
      .catch(ex => dispatch(notIsLoading()))
  }
}

export function addConvocacao(payload) {
  return [{ type: 'ADD_CONVOCACAO', convocacao: payload }, clear()]
}

export function changeConvocacaosList(convocacao, totalRows) {
  return {
    type: 'FETCHED_ALL_CONVOCACAO',
    convocacao: convocacao,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CONVOCACAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CONVOCACAO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CONVOCACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editConvocacaosDetails(convocacao) {
  return {
    type: 'CONVOCACAO_DETAIL',
    id: convocacao.id,
    data: convocacao.data,
    observacoes: convocacao.observacoes,
    idEmpresa: convocacao.idEmpresa,
    empresa: convocacao.empresa,
    idObra: convocacao.idObra,
    obra: convocacao.obra,
    aceita: convocacao.aceita
  }
}

export function updatedConvocacaoInfo() {
  return {
    type: 'CONVOCACAO_UPDATED'
  }
}

function failure(error) {
  return { type: 'EDIT_CONVOCACAO_FAILURE', error }
}

export function createConvocacaoInfo() {
  return {
    type: 'CONVOCACAO_CREATED_SUCCESSFULLY'
  }
}
export function isLoading() {
  return {
    type: 'CONVOCACAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CONVOCACAO_NOTISLOADING'
  }
}
