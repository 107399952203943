import React, { useState, useEffect } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  MenuItem,
  Input,
  Skeleton
} from '@mui/material'
import {
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import { notificacaoAction, notificacaoFiltroAction, usuarioAction } from '_actions'
import { useDispatch, useSelector, connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ModalNotificacaoFiltrosItens = (props) => {

  const [selectedItems, setSelectedItems] = useState([])
  const [idTipoUsuario, setIdTipoUsuario] = useState(0);
  const dispatch = useDispatch()
  const { classes } = props
  const formRef = React.createRef(null);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order, setOrder] = useState('nome');
  const [direction, setDirection] = useState('asc');

  const { openModalNotificacaoFiltrosItens, isLoading } = useSelector(
    (state) => state.notificacao
  )

  const { usuario } = useSelector(
    (state) => state.usuario
  )

  const isLoadingTipoUsuario = useSelector(
    (state) => state.tipoUsuario.isLoading
  )

  const totalRegistrosUsuarios = useSelector(
    (state) => state.tipoUsuario.totalRegistros
  )

  const fetchData = (page, limit, term) => {

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      Id: idTipoUsuario
    };

    if (props.notificacaoFiltro.filtro == 'usuariosAdmin') {
      setIdTipoUsuario(1);
    } else if (props.notificacaoFiltro.filtro == 'usuariosGestorOrgaoPublico') {
      setIdTipoUsuario(2);
    } else if (props.notificacaoFiltro.filtro == 'usuariosEmpresa') {
      setIdTipoUsuario(3);
    } else if (props.notificacaoFiltro.filtro == 'usuariosSistema') {
      setIdTipoUsuario(4);
    } else {
      dispatch(usuarioAction.clear())
    }

    if (idTipoUsuario != 0) {
      dispatch(usuarioAction.getUsuarioByTipo(filterModel));
    }
  };

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, [idTipoUsuario])

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
    setSelectedItems([])
  }, [props.notificacaoFiltro.filtro]);

  useEffect(() => {

  }, [totalRegistrosUsuarios]);

  useEffect(() => {
    setSelectedItems([])
  }, [openModalNotificacaoFiltrosItens]);

  const handleClose = () => {
    dispatch(notificacaoAction.openModalNotificacaoFiltrosItens(false))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      id: 0,
      idNotificacao: props.notificacao.id,
      filtro: props.notificacaoFiltro.filtro,
      notificacoesFiltrosItens: selectedItems ? selectedItems : []
    }

    dispatch(notificacaoFiltroAction.createNotificacaoFiltro(payload))
    dispatch(notificacaoAction.openModalNotificacaoFiltrosItens(false))

  }

  const handleClick = (id) => {
    const isSelected = selectedItems.some((n) => n.chave == id)
    if (isSelected) {
      const newSelected = selectedItems.filter((n) => n.chave !== id)
      setSelectedItems(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idNotificaoFiltro: 0,
        chave: id
      }
      setSelectedItems([...selectedItems, newSelecteds])
    }
  }

  useEffect(() => {

  }, [selectedItems])

  const isItemSelected = (id) => {
    return selectedItems.some((n) => n.chave == id)
  }

  const handleChangeNotificacaoFiltro = (prop) => (event) => {
    dispatch(notificacaoFiltroAction.onChangeProps(prop, event))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Dialog
      open={openModalNotificacaoFiltrosItens}
      onClose={handleClose}
      aria-labelledby="Descrição tipo de ingresso"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Seleção de itens para o filtro
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <ValidatorForm
            className={classes.form}
            ref={formRef}
            id="formNotificacao"
            onSubmit={(event) => handleSubmit(event)}
          >
            <Grid container>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <SelectValidator
                  className={classes.textField}
                  validators={['required']}
                  label="Filtro"
                  labelId="filtro"
                  errorMessages={['Campo obrigatório!']}
                  value={props.notificacaoFiltro.filtro || ''}
                  onChange={handleChangeNotificacaoFiltro('filtro')}
                  name="filtro"
                  fullWidth
                  inputProps={{
                    name: "Filtro",
                    id: "filtro"
                  }}
                  input={<Input id="filtro" />}
                  margin="normal"
                  variant="outlined"
                  id="filtro"
                >
                  <MenuItem value={'usuariosAdmin'}>Usuários administradores cadastrados no sistema</MenuItem>
                  <MenuItem value={'usuariosGestorOrgaoPublico'}>Usuários gestores de órgão público cadastrados no sistema</MenuItem>
                  <MenuItem value={'usuariosEmpresa'}>Usuários de empresas cadastrados no sistema</MenuItem>
                  <MenuItem value={'usuariosSistema'}>Usuários do tipo sistema cadastrados no sistema</MenuItem>
                </SelectValidator>
              </Grid>
              {props.notificacaoFiltro.filtro !== 'usuariosCadastradosApp' ?
                <>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Input
                      id="term"
                      label="term"
                      placeholder="Filtro"
                      value={term || ''}
                      onChange={handleChange('term')}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'center', alignContent: 'center' }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell align="right">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(props.notificacaoFiltro.filtro == 'usuariosAdmin' || props.notificacaoFiltro.filtro == 'usuariosGestorOrgaoPublico' || props.notificacaoFiltro.filtro == 'usuariosEmpresa' || props.notificacaoFiltro.filtro == 'usuariosSistema') ?
                            (undefined !== usuario && usuario.length
                              ? usuario.map((n) => {
                                return (
                                  <TableRow hover key={n.id}>
                                    <TableCell component="th" scope="row">
                                      {n.nome ? n.nome.toUpperCase() : ''} {n.sobrenome ? n.sobrenome.toUpperCase() : ''}
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        onClick={() => handleClick(n.id)}
                                        sx={{
                                          color: '#1C5585',
                                          '&.Mui-checked': {
                                            color: '#1C5585',
                                          }
                                        }}
                                        checked={isItemSelected(n.id)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              :
                              (
                                isLoadingTipoUsuario ?
                                  Array.apply(null, { length: 10 }).map((e, i) => (
                                    <TableRow hover>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                        <Skeleton animation="wave" />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                  :
                                  <TableRow>
                                    <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                                  </TableRow>
                              )
                            )
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      count={props.notificacaoFiltro.filtro === 'lojasFidelizacoes' ?
                        (props.notificacaoFiltro.filtro === 'tipoUsuariosFidelizacoes' || props.notificacaoFiltro.filtro === 'tipoUsuariosTrocas') ? totalRegistrosUsuarios
                          : 0 : 0}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={limit}
                      onRowsPerPageChange={handleChangeLimit}
                    />
                  </Grid>
                </>
                : null}
            </Grid>
          </ValidatorForm>
        </DialogContent>
        <DialogActions>
          <Grid
            style={{ padding: 5 }}
            spacing={2}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button onClick={handleClose} variant="outlined"
                style={{ color: '#000', borderColor: '#000' }}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#000', color: '#FFF' }}
                endIcon={isLoading && <CircularProgress size={24} />}
              >
                {isLoading
                  ? 'Salvando...'
                  : 'Salvar'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ModalNotificacaoFiltrosItens.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalNotificacaoFiltrosItensPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(ModalNotificacaoFiltrosItens))
)
export { connectedModalNotificacaoFiltrosItensPage as ModalNotificacaoFiltrosItens }
