import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, orgaoAction } from '../../_actions'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TitleContentInformation from '../TitleContentInformation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import { cnpjMask, stringUtils } from '../../utils'
import { withRouter } from 'react-router-dom'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import CustomButton from '../../components/CustomButton/CustomButton'
import Stars from '../../components/Stars/Stars'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS } from '../../utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const RelatorioEmpresaPorOrgao = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio } = props.relatorio
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio

  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [valueOrgao, setValueOrgao] = useState(null);
  const [inputValueOrgao, setInputValueOrgao] = useState('');
  const [openOrgao, setOpenOrgao] = useState(false)

  const [ready, setReady] = React.useState(false);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { usuarioOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { orgao } = useSelector(
    (state) => state.orgao)

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.length;

      return total;

    } else {
      return 0;
    }
  }

  useEffect(() => {
    dispatch(orgaoAction.getOrgaoSelect());
    dispatch(relatorioAction.clear());
  }, []);

  const optionsOrgaos = orgao.map(u => ({ id: u.value, nome: u.text }));

  const handlePrint = () => {
    if (valueOrgao && valueOrgao != undefined) {
      window.open('/relatorioEmpresaPorOrgaoPrint', '_blank');
    }
  }

  const handleExportExcel = () => {

    let payload = {
      IdOrgao: valueOrgao ? valueOrgao.id : 0,
      RazaoSocial: razaoSocial,
      Cnpj: cnpj ? cnpj.split('.').join('').replace(/[-/.]/g, '') : ''
    };

    dispatch(relatorioAction.getRelatorioEmpresaOrgaoArquivo(payload))
  }

  useEffect(() => {
    if (idOrgao != 0 && idOrgao != '') {
      if (usuarioOrgao && usuarioOrgao.length > 0) {
        let selectedOrgao = usuarioOrgao.filter(
          (item) => item.idOrgao == idOrgao
        )
        setValueOrgao(usuarioOrgao.length > 0 ? selectedOrgao[0].orgao : '')
      }
    }
  }, [idOrgao])

  const handleSubmit = (event) => {
    if (valueOrgao && valueOrgao != undefined) {

      let payload = {
        IdOrgao: valueOrgao ? valueOrgao.id : 0,
        RazaoSocial: razaoSocial,
        Cnpj: cnpj ? cnpj.split('.').join('').replace(/[-/.]/g, '') : ''
      };

      dispatch(relatorioAction.getRelatorioEmpresaPorOrgao(payload, true));
    } else {
      toast.error('Selecione um órgão!')
    }
  };

  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoCompleteOrgao(valueOrgao)
    }
  }, [valueOrgao])

  const handleChangeAutoCompleteOrgao = (obj) => {
    if (obj != null) {
      let selectedOrgao = orgao.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        orgaoAction.changeDetailsOrgao(
          selectedOrgao[0]
        )
      )
    } else {

    }
    setOpenOrgao(false)
  }

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.relatorio.isLoading])

  return (
    <Page className={classes.root} title="Relatório - Empresas">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioTrocas"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <TitleContentInformation
                        subTitle={'RELATÓRIO'}
                        title={'Empresas por Órgão'}
                      />
                      <Grid item lg={1} md={1} xl={1} xs={12}>
                        <IconButton
                          aria-label="Ajuda"
                          component="a"
                          onClick={() =>
                            handleOpenModalHelp()
                          }
                          size="small"
                        >
                          <Tooltip title={'Ajuda'}>
                            <Tooltip>
                              <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                            </Tooltip>
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={5} md={5} xl={5} xs={12} style={{ marginTop: 17 }}>
                        <Autocomplete
                          value={valueOrgao}
                          onChange={(event, newValue) => {
                            setValueOrgao(newValue);
                          }}
                          inputValue={inputValueOrgao}
                          onInputChange={(event, newInputValueOrgao) => {
                            setInputValueOrgao(newInputValueOrgao);
                          }}
                          id="controllable-states-demo"
                          options={optionsOrgaos}
                          margin="normal"
                          disabled={(idOrgao != null && idOrgao != 0 && idOrgao != '')}
                          variant="outlined"
                          defaultValue={valueOrgao}
                          getOptionLabel={(optionOrgao) => (optionOrgao ? optionOrgao.nome : '')}
                          renderInput={(params) => <TextField {...params} label="Órgão *... " fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="razaoSocial"
                          label="Razão Social"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={(e) => setRazaoSocial(e.target.value)}
                          value={razaoSocial || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="cnpj"
                          label="CNPJ"
                          margin="normal"
                          onChange={(e) => setCnpj(e.target.value)}
                          value={cnpjMask(cnpj) || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <CustomButton
                            type="submit"
                            variant="contained"
                            dark={true}
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFilePdf} style={{ verticalAlign: 'middle' }} />}
                          >
                            Gerar PDF
                          </CustomButton>
                        </Grid>
                        <Grid>
                          <CustomButton
                            type="button"
                            onClick={() => handleExportExcel()}
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faFileExcel} style={{ verticalAlign: 'middle' }} />}
                          >
                            Exportar para Excel
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>ÓRGÃO</strong></TableCell>
                        <TableCell><strong>RAZÃO SOCIAL</strong></TableCell>
                        <TableCell><strong>CNPJ</strong></TableCell>
                        <TableCell><strong>NOTA</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.orgao ? n.orgao.toUpperCase() : '-'}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.nomeEmpresa ? n.nomeEmpresa.toUpperCase() : ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.cnpj ? cnpjMask(n.cnpj) : ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.nota ? <Stars nota={n.nota} avaliacoes={n.avaliacoes} /> : ''}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (valueOrgao != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={6} colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={3} align="center">Informe um órgão</TableCell>
                          </TableRow>
                        )
                      }
                      {undefined !== relatorio && relatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={2} colSpan={2}><b>TOTAL DE EMPRESAS</b></TableCell>
                          <TableCell rowSpan={2}><b>{getTotal()}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
        no={() => <AccessDenied />}
      />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.RELATORIO_EMPRESA_ORGAO}
          />
          :
          null
        )
      }
    </Page>
  )
}
RelatorioEmpresaPorOrgao.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioEmpresaPorOrgaoPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(RelatorioEmpresaPorOrgao))
)
export { connectedRelatorioEmpresaPorOrgaoPage as RelatorioEmpresaPorOrgao }
