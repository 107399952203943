import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import JoditEditor from "jodit-react";
import Typography from '@mui/material/Typography';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Input,
  Grid,
  MenuItem
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { parametroAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ParametroEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, parametro, className, ...rest } = props
  const editor = useRef(null)
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [content, setContent] = useState('');
  const [ready, setReady] = React.useState(false);

  const config = {
    readonly: false
  }

  useEffect(() => {

    dispatch(parametroAction.clear())

    if (params.id) {
      dispatch(parametroAction.getParametroById(params.id))
    } else {
      dispatch(parametroAction.clear())
    }
  }, []);

  useEffect(() => {
    dispatch(parametroAction.onChangePropsHtml('valor', content))
  }, [content]);

  const handleChange = (prop) => (event) => {
    dispatch(parametroAction.onChangeProps(prop, event))
  }

  useEffect(() => {

  }, [ready, props.parametro.isLoading])

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      descricao: props.parametro.descricao,
      chave: props.parametro.chave,
      valor: (props.parametro.valor).toString(),
      tipo: props.parametro.tipo
    }

    if (params.id) {
      dispatch(parametroAction.editParametroInfo(params.id, payload, true))
    } else {
      dispatch(parametroAction.createParametro(payload))
    }

  };

  const handleSelectChange = prop => event => {

    var event2 = {
      type: 'selectValidator',
      target: {
        value: event.value
      }
    };

    dispatch(parametroAction.onChangeProps(prop, event2));

  };

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Parâmetro'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Parâmetro - Editar Parâmetro'
          : 'Gerência de Parâmetro - Novo Parâmetro'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >

        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formParametro"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Parâmetros"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12} >
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={5} xl={4} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descrição"
                        InputLabelProps={{
                          shrink: true
                        }}
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('descricao')}
                        value={props.parametro.descricao}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={5} xl={4} xs={12}>
                      {(props.parametro.id > 0 ?
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="chave"
                          label="Chave"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          disabled
                          onChange={handleChange('chave')}
                          value={props.parametro.chave}
                          variant="outlined"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                        :
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="chave"
                          InputLabelProps={{
                            shrink: true
                          }}
                          label="Chave"
                          margin="normal"
                          onChange={handleChange('chave')}
                          value={props.parametro.chave}
                          variant="outlined"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      )}

                    </Grid>
                    <Grid item lg={6} md={2} xl={4} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        label="Tipo"
                        labelId="tipo"
                        InputLabelProps={{
                          shrink: true
                        }}
                        errorMessages={['Campo obrigatório!']}
                        value={props.parametro.tipo}
                        onChange={handleChange('tipo')}
                        name="tipo"
                        fullWidth
                        inputProps={{
                          name: "Tipo",
                          id: "tipo"
                        }}
                        input={<Input id="tipo" />}
                        margin="normal"
                        variant="outlined"
                        id="tipo"
                      >
                        <MenuItem value={'H'}>HTML</MenuItem>
                        <MenuItem value={'N'}>Numérico</MenuItem>
                        <MenuItem value={'B'}>Sim/Não</MenuItem> {/* boolean */}
                        <MenuItem value={'T'}>Texto</MenuItem>
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      {(props.parametro.tipo === 'H' ?
                        <JoditEditor
                          ref={editor}
                          value={props.parametro.valor}
                          config={config}

                          tabIndex={1} // tabIndex of textarea
                          onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        />
                        : (props.parametro.tipo === 'N' || props.parametro.tipo === 'T') ?
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="valor"
                            InputLabelProps={{
                              shrink: true
                            }}
                            label="Valor"
                            margin="normal"
                            onChange={handleChange('valor')}
                            value={props.parametro.valor}
                            variant="outlined"
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            type={(props.parametro.tipo === 'N' ? 'number' : 'text')}
                          />
                          :
                          <SelectValidator
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            id="valor"
                            label="Valor"
                            value={props.parametro.valor ? JSON.parse(props.parametro.valor) : false}
                            onChange={handleChange('valor')}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            labelId="valor"
                          >
                            <MenuItem disabled value="">
                              <em>Valor</em>
                            </MenuItem>
                            <MenuItem value={true}>SIM</MenuItem>
                            <MenuItem value={false}>NÃO</MenuItem>
                          </SelectValidator>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    variant="outlined"
                    style={{ color: '#000', borderColor: '#000' }}
                    onClick={() => history.push('/parametros')}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: '#000' }}
                    disabled={props.parametro.isLoading}
                    startIcon={<SaveIcon />}
                    endIcon={props.parametro.isLoading && <CircularProgress size={24} />}
                  >
                    {params.id ? (props.parametro.isLoading ? 'Atualizando parâmetro...' : 'Atualizar') : (props.parametro.isLoading ? 'Salvando parâmetro...' : 'Salvar')}
                  </Button>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}


ParametroEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return state
}

const connectedParametroEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(ParametroEdit))
)
export { connectedParametroEditPage as ParametroEdit }
