import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { uploadFile } from '_services'
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  FormLabel,
  Grid,
  Input,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  obraAction,
  obraOrcamentoAction
} from '../../_actions'
import { toast } from 'react-toastify';
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

export const ObraMotivoModal = ({ modal, toggle, props, idObra, valorOrcamentoAprovado, isItensNaoAprovados, itensAprovados }) => {

  const formRef = React.createRef(null);

  const [motivo, setMotivo] = useState('');

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    if (idObra) {
      dispatch(obraAction.getObraById(idObra))
    }
  }, [idObra])

  function isMenorPreco(valor) {
    var min = props.obra?.obrasOrcamentos?.filter(function (orcamento) {
      return orcamento.valor != null; // Filtrar os orçamentos com valor diferente de null
    })?.reduce(function (prev, curr) {
      return prev.valor < curr.valor ? prev : curr;
    });

    if (min && min.valor != null && min.valor != 0 && min.valor != '' && min.valor == valor) {
      return true;
    } else {
      return false;
    }
  }


  useEffect(() => {
    console.log('valorOrcamentoAprovado', valorOrcamentoAprovado)
  }, [valorOrcamentoAprovado])

  const handleSubmit = () => {
    // Verifica se o motivo é nulo, indefinido ou uma string vazia
    const motivoInvalido = motivo == null || motivo == undefined || motivo === '';

    // Verifica se o orçamento selecionado não é o de menor preço
    const naoMenorPreco = !isMenorPreco(valorOrcamentoAprovado);

    console.log('naoMenorPreco', naoMenorPreco)


    if (isItensNaoAprovados() == false) {
      if (itensAprovados && itensAprovados.length > 0) {
        if (naoMenorPreco && motivoInvalido) {
          toast.error('É obrigatório o preenchimento de uma justificativa');
        } else {
          let payload = {
            IdObra: idObra,
            Itens: itensAprovados,
            Motivo: motivo
          }
          dispatch(obraOrcamentoAction.editObraOrcamentoAprova(payload));
        }
      }
    } else {
      toast.error('Para aprovar a proposta da obra, é necessário aprovar uma proposta para cada item');
    }
  };

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Aprovar Proposta'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Alterar o Status de uma Proposta"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="motivo"
                    label="Informe a justificativa"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={(event) => setMotivo(event.target.value)}
                    value={motivo || ''}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.obraOrcamento.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.obraOrcamento.isLoading && <CircularProgress size={24} />}
                >
                  {props.obraOrcamento.isLoading ? 'Aprovando proposta...' : 'Aprovar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
