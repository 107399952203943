import { crudService } from '../_services'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'
import { obraEtapaItemAction } from '_actions'

export const obraAction = {
  getObra,
  getObraSemEmpenho,
  getObraSelect,
  getObraSelectTitle,
  getGraficoEvolucaoObra,
  getObraSelectPublic,
  getObraById,
  onChangeProps,
  changeDetailsObra,
  editObraInfo,
  editObraCoordenadas,
  editObraAprova,
  editObraAprovaOrcamento,
  editObraFinalizaLancamentoItens,
  editObraAlteraStatusEmLancamento,
  editObraDevolveParaRevisao,
  editObraRecusa,
  editObraRecusaOrcamento,
  createObra,
  putFinalizaObra,
  deleteObraById,
  clear,
  handleModalCronograma,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getObra(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObrasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraSemEmpenho(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/empenhos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObrasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraSelect(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'obras/select' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeObrasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraSelectTitle(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'obras/select/title' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeObrasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getGraficoEvolucaoObra(id) {
  return dispatch => {
    let apiEndpoint = 'obras/graficoEvolucaoObra/' + id;
    crudService.get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(editGraficosDetails(response.data));
        }
      }).catch((err) => {
        console.log(err);
      });
  };
}

export function editGraficosDetails(graficoEvolucaoObra) {
  return {
    type: "GRAFICO_EVOLUCAO_OBRA_DETAIL",
    graficoEvolucaoObra: graficoEvolucaoObra
  };
}

function getObraSelectPublic(filterModelPublic) {
  return (dispatch) => {
    let apiEndpoint = `obras/public${filterModelPublic}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObrasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsObra(obra) {
  return (dispatch) => {
    if (obra) {
      dispatch(onChangeDetailsObra(obra))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsObra(obra) {
  return [
    {
      type: 'CHANGE_DETAILS_OBRA',
      id: obra.id,
      titulo: obra.titulo,
      descritivo: obra.descritivo,
      dataInicio: obra.dataInicio,
      dataTermino: obra.dataTermino
    }
  ]
}

function createObra(payload, idOrgao, isObra, isProjetoTecnico, isReparo) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obras'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          if (isProjetoTecnico) {
            history.push('/projetosTecnicos')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsProjetoTecnico: true }))
          } else if (isReparo) {
            history.push('/reparosPrevencoes')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsReparoPrevencao: true }))
          } else if (isObra) {
            history.push('/obras')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: true }))
          } else {
            history.push('/materiais')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: false }))
          }
        } else {
          // toast.error('Oops! Erro ao cadastrar Obra! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar Obra! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_FAILURE', error }
  }
}

function editObraCoordenadas(idObra, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasCoordenadas/' + idObra
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function request(payload) {
    return { type: 'EDIT_OBRA_REQUEST', payload }
  }
  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function putFinalizaObra(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/finalizar/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          history.push('/obras')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_CLEAR'
  }
}

function editObraInfo(id, payload, idOrgao, isObra, isProjetoTecnico, isReparo) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obras/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          if (isProjetoTecnico) {
            history.push('/projetosTecnicos')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsProjetoTecnico: true }))
          } else if (isReparo) {
            history.push('/reparosPrevencoes')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsReparoPrevencao: true }))
          } else if (isObra) {
            history.push('/obras')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: true }))
          } else {
            history.push('/materiais')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: false }))
          }
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function editObraAprova(id, idOrgao, isObra, isProjetoTecnico, isReparo) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/aprova/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          if (isProjetoTecnico) {
            history.push('/projetosTecnicos')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsProjetoTecnico: true }))
          } else if (isReparo) {
            history.push('/reparosPrevencoes')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsReparoPrevencao: true }))
          } else if (isObra) {
            history.push('/obras')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: true }))
          } else {
            history.push('/materiais')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: false }))
          }
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function editObraRecusa(id, idOrgao, isObra, isProjetoTecnico, isReparo) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          if (isProjetoTecnico) {
            history.push('/projetosTecnicos')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsProjetoTecnico: true }))
          } else if (isReparo) {
            history.push('/reparosPrevencoes')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsReparoPrevencao: true }))
          } else if (isObra) {
            history.push('/obras')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: true }))
          } else {
            history.push('/materiais')
            dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: false }))
          }
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }
}

function editObraAprovaOrcamento(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/aprova/orcamento/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/obras')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function editObraRecusaOrcamento(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/recusa/orcamento/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/obras')
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function editObraFinalizaLancamentoItens(id, idObra, idObraEtapa) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/finalizaLancamentoItens/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(getObraById(idObra))

          let filterModel = {
            Id: idObraEtapa
          };

          dispatch(obraEtapaItemAction.getObraEtapaItem(filterModel, true));
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function editObraDevolveParaRevisao(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/devolveParaRevisao/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(getObraById(id))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function editObraAlteraStatusEmLancamento(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/alterarStatusEmLancamento/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(getObraById(id))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_FAILURE', error }
  }
}

function request(payload) {
  return { type: 'EDIT_OBRA_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_OBRA_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_OBRA_FAILURE', error }
}


function getObraById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obras/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObrasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraById(id, idOrgao, payload, isObra, isProjetoTecnico, isReparoPrevencao) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obras/delete/' + id
    crudService.put(apiEndpoint, payload).then((response) => {
      if (response && response.request.status === 200) {
        dispatch(notIsLoading())
        if (isProjetoTecnico) {
          history.push('/projetosTecnicos')
          dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsProjetoTecnico: true }))
        } else if (isReparoPrevencao) {
          history.push('/reparosPrevencoes')
          dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsReparoPrevencao: true }))
        } else if (isObra) {
          history.push('/obras')
          dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: true }))
        } else {
          history.push('/materiais')
          dispatch(getObra({ IdOrgao: idOrgao, Page: 0, IsObra: false }))
        }
      }
    })
  }
}

export function changeObrasList(obra, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA',
    obra: obra,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObrasDetails(obra) {
  return {
    type: 'OBRA_DETAIL',
    id: obra.id,
    descritivo: obra.descritivo,
    titulo: obra.titulo,
    idUsuario: obra.idUsuario,
    idTipoObra: obra.idTipoObra,
    dataInicio: obra.dataInicio,
    dataTermino: obra.dataTermino,
    idOrgao: obra.idOrgao,
    idTabelaInsumo: obra.idTabelaInsumo,
    anoTabelaInsumo: obra.anoTabelaInsumo,
    mesTabelaInsumo: obra.mesTabelaInsumo,
    idEstadoTabelaInsumo: obra.idEstadoTabelaInsumo,
    horasRecebimentoOrcamentos: obra.horasRecebimentoOrcamentos,
    caminhoImagem: obra.caminhoImagem,
    onerado: obra.onerado,
    credenciamento: obra.credenciamento,
    urgencia: obra.urgencia,
    latitude: obra.latitude,
    longitude: obra.longitude,
    bdi: obra.bdi,
    valorReferencia: obra.valorReferencia,
    valorReferenciaComBdi: obra.valorReferenciaComBdi,
    valorFinal: obra.valorFinal,
    orgao: obra.orgao,
    ultimoEvento: obra.ultimoEvento,
    empresa: obra.empresa,
    eventos: obra.eventos,
    usuario: obra.usuario,
    idUsuarioMudancaSituacao: obra.idUsuarioMudancaSituacao,
    usuarioMudancaSituacao: obra.usuarioMudancaSituacao,
    dataMudancaSituacao: obra.dataMudancaSituacao,
    dataAprovacao: obra.dataAprovacao,
    veiculo: obra.veiculo,
    motivoExclusao: obra.motivoExclusao,
    motivoMudancaHorasRecebimentoOrcamentos: obra.motivoMudancaHorasRecebimentoOrcamentos,
    tipoObra: obra.tipoObra,
    obrasAnexos: obra.obrasAnexos,
    obrasEtapas: obra.obrasEtapas,
    obrasOrcamentos: obra.obrasOrcamentos,
    obrasCoordenadas: obra.obrasCoordenadas,
    obrasCobrancas: obra.obrasCobrancas,
    tabelaInsumo: obra.tabelaInsumo,
    estadoTabelaInsumo: obra.estadoTabelaInsumo,
    obrasAvaliacoes: obra.obrasAvaliacoes,
    obrasEmpenhos: obra.obrasEmpenhos,
    percentualConclusaoObra: obra.percentualConclusaoObra,
    percentualObraOrcamentosPagos: obra.percentualObraOrcamentosPagos
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_CREATED_SUCCESSFULLY'
  }
}
export function deleteObrasDetails() {
  return {
    type: 'DELETED_OBRA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_ISLOADING'
  }
}

export function handleModalCronograma() {
  return {
    type: 'OBRA_HANDLE_MODAL_CADASTRO'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_NOTISLOADING'
  }
}
