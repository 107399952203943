import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import PhotoIcon from '@mui/icons-material/Photo';
import { withStyles } from '@mui/styles'
import TableViewIcon from '@mui/icons-material/TableView';
import Typography from '@mui/material/Typography'
import DescriptionIcon from '@mui/icons-material/Description';
import { toast } from 'react-toastify'
import { uploadFile } from '_services'
import HelpIcon from '@mui/icons-material/Help';
import { MANUAIS_CATEGORIAS } from '../../utils'
import {
  Avatar,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
  Skeleton,
  Input
} from '@mui/material'
import {
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  obraAnexoAction,
  tipoAnexoAction
} from '../../_actions'
import { withRouter, } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit'
import { useDebounce } from 'use-debounce';
import { AccessControl } from '../../components/Utils/AccessControl'
import { isEmpty } from 'lodash'
import CustomButton from '../../components/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

function isEditable(e) {
  if (e == '2' || e == '3' || e == '4' || e == '5' || e == '6') {
    return false
  }
  else return true
}

const ObraAnexoEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const { classes, idObra, ...rest } = props
  const { match: { params } } = props;
  const { totalRegistros } = props.obraAnexo
  const { isLoading } = props.obra
  const formRef = React.createRef(null);
  const [key, setKey] = useState(1);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');

  const [open, setOpen] = useState(false)
  const [imagem, setImagem] = useState('')
  const [file, setFile] = useState(undefined)

  const { obraAnexo } = useSelector(
    (state) => state.obraAnexo)

  const { tipoAnexo } = useSelector(
    (state) => state.tipoAnexo)

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  useEffect(() => {
    dispatch(obraAnexoAction.clear())

    dispatch(tipoAnexoAction.getTiposAnexosSelect())

  }, []);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdObra: (idObra && idObra != '' && idObra)
      };

      dispatch(obraAnexoAction.getObraAnexo(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idObra]);

  useEffect(() => {

  }, [totalRegistros, isLoading]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (id) => {
    dispatch(obraAnexoAction.getObraAnexoById(id))
  }

  const handleClean = () => {
    dispatch(obraAnexoAction.clearDetail())
    setKey(0);
  }

  const handleDelete = (id) => {
    confirm({
      title: 'Você deseja excluir este anexo?',
      description: 'Essa operação é irreversível',
      disabled: props.obraAnexo.isLoading,
      confirmationText: props.obraAnexo.isLoading ? 'Excluindo anexo...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraAnexoAction.deleteObraAnexoById(id))
    })
  }

  const handleChange = (prop) => (event) => {
    dispatch(obraAnexoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    if (props.obraAnexo.idTipoAnexo == 0 || props.obraAnexo.idTipoAnexo == null) {
      toast.error('Informe o Tipo de Anexo!')
    }
    else if (props.obraAnexo.caminhoAnexo == '' || props.obraAnexo.caminhoAnexo == null) {
      toast.error('Informe o Arquivo do Anexo!')
    } else {
      let payload = {
        id: props.obraAnexo.id,
        idObra: parseInt(idObra),
        idTipoAnexo: props.obraAnexo.idTipoAnexo,
        caminhoAnexo: props.obraAnexo.caminhoAnexo
      }

      if (props.obraAnexo.id) {
        dispatch(obraAnexoAction.editObraAnexoInfo(props.obraAnexo.id, payload, true))
      } else {
        dispatch(obraAnexoAction.createObraAnexo(payload))
      }
    }

  };

  const handleOpenFile = (link) => {
    window.open(link);
  }

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    if (file && file != undefined && file != null) {
      setOpen(true)
      upload()
    }
  }, [file])

  const upload = () => {
    uploadFile(file)
      .then((response) => {
        setTimeout(function () {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(obraAnexoAction.onChangeProps('caminhoAnexo', event2))

          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Anexo'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Anexo'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (props.obraAnexo.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (

    //{isEditable(props.obra.ultimoEvento) == true ?
    //permitir enviar anexos de NOTAS mesmo depois de já aprovado o orçamento

    <Grid item lg={12} md={12} xl={12} xs={12}>
      <Grid item md={12} xs={12}>
        <ValidatorForm
          className={classes.form}
          ref={formRef}
          id="formAnexo"
          onSubmit={(event) => handleSubmit(event)}
        >
          <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <CardHeader
              subheader="Cadastro de Anexo"
              title={<SegHeader />}
            />
            <Grid item lg={1} md={1} xl={1} xs={12}>
              <IconButton
                aria-label="Ajuda"
                component="a"
                onClick={() =>
                  handleOpenModalHelp()
                }
                size="small"
              >
                <Tooltip title={'Ajuda'}>
                  <Tooltip>
                    <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                  </Tooltip>
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <CardContent lg={12} md={12} xl={12} xs={12}>
            <Grid container spacing={2}>
              <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <Input
                    id="arquivo"
                    className={classes.textField}
                    label="Arquivo *"
                    onChange={e => handleChangeFile(e)}
                    type="file"
                    placeHolder="Escolher arquivo *"
                    formControl
                    key={key}
                    required
                    margin="dense"
                  />
                </Grid>
                <Grid item lg={3} md={3} xl={3} xs={12}>
                  <SelectValidator
                    variant="outlined"
                    id="idTipoAnexo"
                    label="Tipo Anexo *"
                    margin="normal"
                    onChange={handleChange('idTipoAnexo')}
                    value={props.obraAnexo.idTipoAnexo}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="idTipoAnexo"
                  >
                    <MenuItem disabled value="">
                      <em>Tipo Anexo *</em>
                    </MenuItem>
                    {!isEmpty(tipoAnexo) &&
                      undefined !== tipoAnexo &&
                      tipoAnexo.length &&
                      tipoAnexo.map((row) => (
                        <MenuItem key={row.value} value={row.value}>
                          {row.text}
                        </MenuItem>
                      ))}
                  </SelectValidator>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                * Campos obrigatórios
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12} style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlgin: 'right',
              justifyContent: 'right'
            }}>
              <CustomButton
                variant="outlined"
                onClick={() => handleClean()}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                type="submit"
                variant="contained"
                dark={true}
                disabled={props.obraAnexo.isLoading || props.obraAnexo.valorReferencia == '2' || open}
                startIcon={<SaveIcon />}
                endIcon={props.obraAnexo.isLoading || open && <CircularProgress size={24} />}
              >
                {props.obraAnexo.id ? (props.obraAnexo.isLoading ? 'Atualizando ObraAnexo...' : 'Atualizar') : (props.obraAnexo.isLoading ? 'Salvando ObraAnexo...' : 'Salvar')}
              </CustomButton>
            </Grid>
          </CardActions>
        </ValidatorForm>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          <Input
            id="term"
            label="term"
            placeholder="Filtro"
            value={term}
            fullWidth
            onChange={handleChange('term')}
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Tipo de Anexo</TableCell>
              <TableCell>Anexo</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== obraAnexo && obraAnexo.length
              ? obraAnexo.map((n) => {

                const pathArray = n.caminhoAnexo.split('/');
                const nomeArquivo = pathArray[pathArray.length - 1];

                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.id ? n.id : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.tipoAnexo ? n.tipoAnexo.nome : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.caminhoAnexo && typeof n.caminhoAnexo === 'string' ? (
                        n.caminhoAnexo.toLowerCase().includes('pdf') ? (
                          <Tooltip title="Visualizar arquivo em uma nova aba">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                alignContent: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => handleOpenFile(n.caminhoAnexo)}
                            >
                              {nomeArquivo} <PictureAsPdfIcon style={{ paddingLeft: 3 }} />
                            </div>
                          </Tooltip>
                        ) : (
                          (n.caminhoAnexo.toLowerCase().includes('.jpg') ||
                            n.caminhoAnexo.toLowerCase().includes('.jpeg') ||
                            n.caminhoAnexo.toLowerCase().includes('.png') ||
                            n.caminhoAnexo.toLowerCase().includes('.svg') ||
                            n.caminhoAnexo.toLowerCase().includes('webp')) ? (
                            <Tooltip title="Visualizar imagem em uma nova aba">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  alignContent: 'center',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleOpenFile(n.caminhoAnexo)}
                              >
                                {nomeArquivo} <PhotoIcon style={{ paddingLeft: 3 }} />
                              </div>
                            </Tooltip>
                          ) : n.caminhoAnexo.toLowerCase().includes('.txt') ? (
                            <Tooltip title="Visualizar arquivo em uma nova aba">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  alignContent: 'center',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleOpenFile(n.caminhoAnexo)}
                              >
                                {nomeArquivo} <DescriptionIcon style={{ paddingLeft: 3 }} />
                              </div>
                            </Tooltip>
                          ) : (
                            (n.caminhoAnexo.toLowerCase().includes('.xls') ||
                              n.caminhoAnexo.toLowerCase().includes('.xlsx') ||
                              n.caminhoAnexo.toLowerCase().includes('.csv') ||
                              n.caminhoAnexo.toLowerCase().includes('.doc') ||
                              n.caminhoAnexo.toLowerCase().includes('.docx') ||
                              n.caminhoAnexo.toLowerCase().includes('.dwg') ||
                              n.caminhoAnexo.toLowerCase().includes('.rte') ||
                              n.caminhoAnexo.toLowerCase().includes('.rvt') ||
                              n.caminhoAnexo.toLowerCase().includes('.ifc') ||
                              n.caminhoAnexo.toLowerCase().includes('.pln')) && (
                              <Tooltip title="Visualizar arquivo em uma nova aba">
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleOpenFile(n.caminhoAnexo)}
                                >
                                  {nomeArquivo} <TableViewIcon style={{ paddingLeft: 3 }} />
                                </div>
                              </Tooltip>
                            )
                          )
                        )
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <AccessControl
                        rule={'obrasAnexos.edit'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Edit"
                            component="a"
                            onClick={() => handleEdit(n.id)}
                            size="small"
                          >
                            <Tooltip title={'Editar'}>
                              <Tooltip >
                                <EditIcon />
                              </Tooltip>
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'obrasAnexos.delete'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Delete"
                            onClick={() => handleDelete(n.id)}
                            size="small"
                          >
                            <Tooltip title="Excluir">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                    </TableCell>
                  </TableRow >
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  <TableRow hover>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRegistros}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeLimit}
      />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_ANEXO}
          />
          :
          null
        )
      }
    </Grid>

  )
}

ObraAnexoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraEditPage = withRouter(
  connect(mapStateToProps, null, null)(withStyles(styles)(ObraAnexoEdit))
)
export { connectedObraEditPage as ObraAnexoEdit }
