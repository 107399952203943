const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obra: [],
  open: false,
  openModalCronograma: false,
  id: '',
  descritivo: '',
  titulo: '',
  idUsuario: 0,
  idTipoObra: 0,
  dataInicio: '',
  duracaoObra: 30,
  idUsuarioMudancaSituacao: 0,
  usuarioMudancaSituacao: [],
  dataMudancaSituacao: '',
  dataAprovacao: '',
  dataTermino: '',
  idOrgao: 0,
  valorReferencia: 0,
  valorReferenciaComBdi: 0,
  valorFinal: 0,
  idTabelaInsumo: '',
  anoTabelaInsumo: '',
  mesTabelaInsumo: '',
  idEstadoTabelaInsumo: '',
  horasRecebimentoOrcamentos: 0,
  onerado: false,
  urgencia: false,
  credenciamento: false,
  latitude: '',
  longitude: '',
  bdi: '',
  ultimoEvento: null,
  caminhoImagem: '',
  motivoExclusao: '',
  motivoMudancaHorasRecebimentoOrcamentos: '',
  tipoObra: [],
  orgao: [],
  empresa: [],
  veiculo: [],
  usuario: [],
  eventos: [],
  obrasAnexos: [],
  obrasEtapas: [],
  obrasOrcamentos: [],
  obrasCobrancas: [],
  obrasAvaliacoes: [],
  obrasCoordenadas: [],
  tabelaInsumo: [],
  estadoTabelaInsumo: [],
  obrasEmpenhos: [],
  percentualConclusaoObra: 0,
  percentualObraOrcamentosPagos: 0,
  graficoEvolucaoObra: { options: [], series: [] }
}

export function obra(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_SUCCESS':
      return {
        ...state,
        id: action.obra.id,
        isLoading: false,
        descritivo: action.obra.descritivo,
        titulo: action.obra.titulo,
        idUsuario: action.obra.idUsuario,
        idTipoObra: action.obra.idTipoObra,
        dataInicio: action.obra.dataInicio,
        dataTermino: action.obra.dataTermino,
        dataAprovacao: action.obra.dataAprovacao,
        idOrgao: action.obra.idOrgao,
        valorReferencia: action.obra.valorReferencia,
        valorReferenciaComBdi: action.obra.valorReferenciaComBdi,
        valorFinal: action.obra.valorFinal,
        idTabelaInsumo: action.obra.idTabelaInsumo,
        anoTabelaInsumo: action.obra.anoTabelaInsumo,
        mesTabelaInsumo: action.obra.mesTabelaInsumo,
        idEstadoTabelaInsumo: action.obra.idEstadoTabelaInsumo,
        horasRecebimentoOrcamentos: action.obra.horasRecebimentoOrcamentos,
        caminhoImagem: action.obra.caminhoImagem,
        onerado: action.obra.onerado,
        credenciamento: action.obra.credenciamento,
        urgencia: action.obra.urgencia,
        latitude: action.obra.latitude,
        longitude: action.obra.longitude,
        bdi: action.obra.bdi,
        ultimoEvento: action.obra.ultimoEvento,
        tipoObra: action.obra.tipoObra,
        orgao: action.obra.orgao,
        empresa: action.obra.empresa,
        eventos: action.obra.eventos,
        usuario: action.obra.usuario,
        idUsuarioMudancaSituacao: action.obra.idUsuarioMudancaSituacao,
        usuarioMudancaSituacao: action.obra.usuarioMudancaSituacao,
        dataMudancaSituacao: action.obra.dataMudancaSituacao,
        veiculo: action.obra.veiculo,
        motivoExclusao: action.obra.motivoExclusao,
        motivoMudancaHorasRecebimentoOrcamentos: action.obra.motivoMudancaHorasRecebimentoOrcamentos,
        obrasEtapas: action.obra.obrasEtapas,
        obrasAnexos: action.obra.obrasAnexos,
        obrasOrcamentos: action.obra.obrasOrcamentos,
        obrasCobrancas: action.obra.obrasCobrancas,
        obrasCoordenadas: action.obra.obrasCoordenadas,
        obrasEmpenhos: action.obra.obrasEmpenhos,
        tabelaInsumo: action.obra.tabelaInsumo,
        estadoTabelaInsumo: action.obra.estadoTabelaInsumo,
        percentualConclusaoObra: action.obra.percentualConclusaoObra,
        obrasAvaliacoes: action.obra.obrasAvaliacoes,
        percentualObraOrcamentosPagos: action.obra.percentualObraOrcamentosPagos,
        graficoEvolucaoObra: action.obra.graficoEvolucaoObra
      }
    case 'GRAFICO_EVOLUCAO_OBRA_DETAIL':
      return {
        ...state,
        graficoEvolucaoObra: action.graficoEvolucaoObra
      };
    case 'CREATE_OBRA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_SUCCESS':
      return {
        ...state,
        id: action.obra.id,
        isLoading: false,
        descritivo: action.obra.descritivo,
        titulo: action.obra.titulo,
        idUsuario: action.obra.idUsuario,
        idTipoObra: action.obra.idTipoObra,
        dataInicio: action.obra.dataInicio,
        dataTermino: action.obra.dataTermino,
        idOrgao: action.obra.idOrgao,
        valorReferencia: action.obra.valorReferencia,
        valorReferenciaComBdi: action.obra.valorReferenciaComBdi,
        valorFinal: action.obra.valorFinal,
        idTabelaInsumo: action.obra.idTabelaInsumo,
        anoTabelaInsumo: action.obra.anoTabelaInsumo,
        mesTabelaInsumo: action.obra.mesTabelaInsumo,
        onerado: action.obra.onerado,
        credenciamento: action.obra.credenciamento,
        urgencia: action.obra.urgencia,
        idEstadoTabelaInsumo: action.obra.idEstadoTabelaInsumo,
        horasRecebimentoOrcamentos: action.obra.horasRecebimentoOrcamentos,
        caminhoImagem: action.obra.caminhoImagem,
        latitude: action.obra.latitude,
        longitude: action.obra.longitude,
        bdi: action.obra.bdi,
        ultimoEvento: action.obra.ultimoEvento,
        tipoObra: action.obra.tipoObra,
        orgao: action.obra.orgao,
        empresa: action.obra.empresa,
        eventos: action.obra.eventos,
        usuario: action.obra.usuario,
        motivoExclusao: action.obra.motivoExclusao,
        motivoMudancaHorasRecebimentoOrcamentos: action.obra.motivoMudancaHorasRecebimentoOrcamentos,
        idUsuarioMudancaSituacao: action.obra.idUsuarioMudancaSituacao,
        usuarioMudancaSituacao: action.obra.usuarioMudancaSituacao,
        dataMudancaSituacao: action.obra.dataMudancaSituacao,
        dataAprovacao: action.obra.dataAprovacao,
        veiculo: action.obra.veiculo,
        obrasAnexos: action.obra.obrasAnexos,
        obrasEtapas: action.obra.obrasEtapas,
        obrasCoordenadas: action.obra.obrasCoordenadas,
        obrasOrcamentos: action.obra.obrasOrcamentos,
        obrasEmpenhos: action.obra.obrasEmpenhos,
        obrasCobrancas: action.obra.obrasCobrancas,
        tabelaInsumo: action.obra.tabelaInsumo,
        estadoTabelaInsumo: action.obra.estadoTabelaInsumo,
        percentualConclusaoObra: action.obra.percentualConclusaoObra,
        obrasAvaliacoes: action.obra.obrasAvaliacoes,
        percentualObraOrcamentosPagos: action.obra.percentualObraOrcamentosPagos,
        graficoEvolucaoObra: action.obra.graficoEvolucaoObra
      }
    case 'EDIT_OBRA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA':
      return {
        ...state,
        obra: action.obra,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        descritivo: action.descritivo,
        titulo: action.titulo,
        idUsuario: action.idUsuario,
        idTipoObra: action.idTipoObra,
        dataInicio: action.dataInicio,
        dataTermino: action.dataTermino,
        dataAprovacao: action.dataAprovacao,
        idOrgao: action.idOrgao,
        valorReferencia: action.valorReferencia,
        valorReferenciaComBdi: action.valorReferenciaComBdi,
        valorFinal: action.valorFinal,
        idTabelaInsumo: action.idTabelaInsumo,
        anoTabelaInsumo: action.anoTabelaInsumo,
        mesTabelaInsumo: action.mesTabelaInsumo,
        onerado: action.onerado,
        credenciamento: action.credenciamento,
        urgencia: action.urgencia,
        idEstadoTabelaInsumo: action.idEstadoTabelaInsumo,
        horasRecebimentoOrcamentos: action.horasRecebimentoOrcamentos,
        caminhoImagem: action.caminhoImagem,
        latitude: action.latitude,
        longitude: action.longitude,
        bdi: action.bdi,
        ultimoEvento: action.ultimoEvento,
        tipoObra: action.tipoObra,
        orgao: action.orgao,
        empresa: action.empresa,
        eventos: action.eventos,
        usuario: action.usuario,
        motivoExclusao: action.motivoExclusao,
        motivoMudancaHorasRecebimentoOrcamentos: action.motivoMudancaHorasRecebimentoOrcamentos,
        idUsuarioMudancaSituacao: action.idUsuarioMudancaSituacao,
        usuarioMudancaSituacao: action.usuarioMudancaSituacao,
        dataMudancaSituacao: action.dataMudancaSituacao,
        veiculo: action.veiculo,
        obrasAnexos: action.obrasAnexos,
        obrasEtapas: action.obrasEtapas,
        obrasOrcamentos: action.obrasOrcamentos,
        obrasCoordenadas: action.obrasCoordenadas,
        obrasCobrancas: action.obrasCobrancas,
        obrasEmpenhos: action.obrasEmpenhos,
        tabelaInsumo: action.tabelaInsumo,
        estadoTabelaInsumo: action.estadoTabelaInsumo,
        percentualConclusaoObra: action.percentualConclusaoObra,
        obrasAvaliacoes: action.obrasAvaliacoes,
        percentualObraOrcamentosPagos: action.percentualObraOrcamentosPagos,
        graficoEvolucaoObra: action.graficoEvolucaoObra
      }
    case 'OBRA_CLEAR':
      return initialState
    case 'OBRA_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obra: [...state.obra, action.data.obra]
      }
    case 'OBRA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'OBRA_HANDLE_MODAL_CADASTRO':
      return {
        ...state,
        openModalCronograma: !state.openModalCronograma
      }
    default:
      return state
  }
}
