import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-18-pdf/renderer';
import WorkIcon from '@mui/icons-material/Work';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Header, Footer } from '../../_reports';
import { phoneMask } from '../../utils'
import { styled } from '@mui/material/styles';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    fontSize: 7,
    fontFamily: 'Roboto-Regular',
    borderTop: 1,
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1
  },
  header: {
    borderRight: 1,
    padding: 5,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center'
  },
  cell: {
    borderRight: 1,
    padding: 3,
    textAlign: 'center'
  }
});

const CustomPage = styled(Page)(({ theme }) => ({
  padding: 20,
  paddingBottom: 25,
  height: '100%',
  width: '100%'
}));

const RelatorioUsuariosPrint = (props) => {

  const [ready, setReady] = React.useState(false);
  const { match: { params } } = props;
  const { relatorio } = props.relatorio

  useEffect(() => {

  }, [props.relatorio]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 1100);
  }, [])

  const onRendered = () => {
  };

  return (
    (ready &&
      <div style={{ width: '100%', height: '100%' }}>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document onRender={onRendered} width='100%'>
            <CustomPage orientation="landscape" size="A4" style={styles.page} wrap={true} >
              <Header title='Relatório de Usuários' />
              <View style={[styles.row, { marginTop: 40 }]} key={0} >
                <View style={[styles.header, { width: 100 }]}>
                  <Text>TIPO</Text>
                </View>
                <View style={[styles.header, { width: 150 }]}>
                  <Text>VÍNCULO</Text>
                </View>
                <View style={[styles.header, { width: 300 }]}>
                  <Text>NOME</Text>
                </View>
                <View style={[styles.header, { width: 200 }]}>
                  <Text>E-MAIL</Text>
                </View>
                <View style={[styles.header, { width: 100 }]}>
                  <Text>TELEFONE/CELULAR</Text>
                </View>
              </View>
              {
                relatorio.length > 0 ? relatorio.map(n => {
                  var retorno = (
                    <View style={[styles.row]} key={n.id} >
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text>{n.tipoUsuario ? n.tipoUsuario == 'ADMINISTRADOR' ?
                          'ADMINISTRADOR'
                          : n.tipoUsuario == 'EMPRESA' ? 'EMPRESA' :
                            n.tipoUsuario == 'ÓRGÃO PÚBLICO' ? 'ÓRGÃO PÚBLICO' : '' : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 150 }]}>
                        <Text> {n.orgao ? n.orgao.toUpperCase() : n.empresa ? n.empresa.toUpperCase() : '-'}</Text>
                      </View>
                      <View style={[styles.cell, { width: 300 }]}>
                        <Text>{n.nomeUsuario ? n.nomeUsuario.toUpperCase() : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 200 }]}>
                        <Text>{n.email ? n.email : ''}</Text>
                      </View>
                      <View style={[styles.cell, { width: 100 }]}>
                        <Text> {n.telefone ? phoneMask(n.telefone) : n.celular ? phoneMask(n.celular) : ''}</Text>
                      </View>
                    </View>
                  );
                  return retorno;
                })
                  : <View style={[styles.row]} key={0} >
                    <View style={[styles.cell, { width: 850 }]}>
                      <Text>Nenhum registro encontrado</Text>
                    </View>
                  </View>
              }
              <Footer />
            </CustomPage>
          </Document>
        </PDFViewer>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return state;
};

const connectedRelatorioUsuariosPrintPage = withRouter(connect(mapStateToProps, null, null)(RelatorioUsuariosPrint));
export { connectedRelatorioUsuariosPrintPage as RelatorioUsuariosPrint };
