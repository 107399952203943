import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Paper } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import { obraAction } from '../../_actions';

const ModalCronograma = ({ obra }) => {
  const dispatch = useDispatch();
  const [table, setTable] = useState({ columns: 0, rows: 0 });
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    getTableSize();
  }, []);

  const getTableSize = () => {
    let innerColumns = 0;
    let innerRows = obra.obrasEtapas?.length;

    obra.obrasEtapas.forEach(etapa => {
      const periodos = etapa.periodos?.split(';');
      if (periodos?.length > innerColumns) {
        innerColumns = periodos.length;
      }
    })

    setTable({ columns: innerColumns, rows: innerRows });
  }

  const handleChangeSelected = (index) => {
    if (selected === index) {
      setSelected(null)
    } else {
      setSelected(index);
    }
  }

  const renderTableHeader = () => {
    return Array.from({ length: table.columns }, (_, index) => {
      return (
        <>
          <TableCell size="small" align="center">
            <strong>%</strong>
          </TableCell>
          <TableCell size="small" align="center" style={{ minWidth: '100px' }}>
            <strong>Período {index + 1}</strong>
          </TableCell>
        </>
      )
    })
  }

  const renderTableBody = () => {
    return obra.obrasEtapas.map((etapa, index) => {
      const total = etapa.obrasEtapasItens.reduce((acc, item) => acc +=
        (item.idObraOrcamentoItem ?
          item.obrasOrcamentosItens.find((itemOrc) => itemOrc.id == item.idObraOrcamentoItem)?.valor
          : item.valorReferenciaComBdi
        )
        , 0);

      const periodos = etapa.periodos?.split(';') || [];

      const cells = [];
      cells.push(
        <TableCell>
          <strong>{etapa.titulo}</strong>
        </TableCell>
      );
      cells.push(
        <TableCell align="center" style={{ minWidth: '100px' }}>
          {'R$ ' + total.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </TableCell>
      );

      if (periodos.length) {
        for (let i = 0; i < table.columns; i++) {
          const porc = Number(periodos[i]);
          cells.push(
            <TableCell align="center" key={i + '%'}>
              <p>{!isNaN(porc) ? porc + '%' : '-'}</p>
            </TableCell>
          );
          cells.push(
            <TableCell align="center" key={i + '$'}>
              <p>{!isNaN(porc) ? 'R$ ' + (total * (porc / 100)).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</p>
            </TableCell>
          );
        }
      }

      return (
        <>
          <TableRow onClick={() => handleChangeSelected(index)} style={{ marginTop: 0, marginBottom: 0, backgroundColor: selected === index ? '#f2f2f2' : '#fff', cursor: 'pointer', borderBottomColor: 'red' }} key={index}>{cells}</TableRow>
          {selected === index && etapa.obrasEtapasItens.map(item => {
            const cells2 = [];
            cells2.push(
              <TableCell style={{ fontSize: '12px' }}>
                {item.descritivo}
              </TableCell>
            );
            cells2.push(
              <TableCell align="center" style={{ minWidth: '100px', fontSize: '12px' }}>
                {'R$ ' + ((item.idObraOrcamentoItem ?
                  item.obrasOrcamentosItens.find((itemOrc) => itemOrc.id == item.idObraOrcamentoItem)?.valor
                  : item.valorReferenciaComBdi
                )).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </TableCell>
            );

            if (periodos.length) {
              for (let i = 0; i < table.columns; i++) {
                const porc = Number(periodos[i]);
                cells2.push(
                  <TableCell align="center" key={i + '%'}>
                  </TableCell>
                );
                cells2.push(
                  <TableCell align="center" key={i + '$'} style={{ fontSize: '12px' }}>
                    <p>{!isNaN(porc) ? 'R$ ' + ((item.idObraOrcamentoItem ?
                      item.obrasOrcamentosItens.find((itemOrc) => itemOrc.id == item.idObraOrcamentoItem)?.valor
                      : item.valorReferenciaComBdi
                    ) * (porc / 100)).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</p>
                  </TableCell>
                );
              }
            }
            return <TableRow style={{ backgroundColor: '#f2f2f2' }}>{cells2}</TableRow>
          })}
        </>
      );
    })
  }

  return (
    <Dialog
      open={obra.openModalCronograma}
      aria-labelledby="Cronograma físico-financeiro da obra"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 16, marginBottom: 0 }}>
        <Typography variant="p" fontSize="1.5rem" marginBottom={2}>CRONOGRAMA FÍSICO-FINANCEIRO</Typography>
        <Button
          variant="text"
          size="small"
          style={{ position: 'absolute', top: 15, right: 0 }}
          onClick={() => dispatch(obraAction.handleModalCronograma())}>
          <CloseIcon style={{ color: '#015192' }} />
        </Button>
      </DialogTitle>
      <TableContainer component={Paper}>
        <Table cellPadding={0}>
          <TableHead>
            <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
              <TableCell size="small">
                <strong>ETAPA</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>TOTAL</strong>
              </TableCell>
              {renderTableHeader()}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableBody()}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog >
  );
}

ModalCronograma.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalCronograma = withRouter(
  connect(mapStateToProps, null, null)(ModalCronograma)
)
export { connectedModalCronograma as ModalCronograma }

export default ModalCronograma;
